import React, { useState, useEffect } from "react";
import { Button, Card, InputField } from "../../components/ui";
import { Gif, Icons, ReactIcons } from "../../assets";
import SelectTokenDialog from "../../components/SelectTokenDialog";
import {
  createSearchParams,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import {
  amountState,
  destinationNetworkState,
  destinationTokenState,
  ErrorMessagesState,
  estimatedSwapTimeState,
  fetchingQuotationState,
  gasFeeEstimationState,
  isSwappingState,
  sourceNetworkState,
  sourceTokenState,
  tokenQuotationInfoState,
} from "../../recoil/swap";
import { useTokenBalance } from "../../hooks";
import { IsObjectEmpty } from "../../utils/helper";
import { useWalletConnector } from "foundry";
import * as swapServer from "../../api/SwapCrud";
import { AxiosError } from "axios";
import {
  getTokenLogoByCABN,
  getTokenSymbolByCABN,
} from "../../utils/CABNHelper";

type SwapSide = "source" | "destination";

const SwapDetailsSelectorCard = ({
  title,
  side,
  quationAmount,
}: {
  title: string;
  side: SwapSide;
  quationAmount?: any;
}) => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const setErrorMessage = useSetRecoilState(ErrorMessagesState);
  const [amount, setAmount] = useRecoilState(amountState);
  const [showDialog, setShowDialog] = useState(false);
  const [estimatedSwapTime, setEstimatedSwapTime] = useRecoilState(
    estimatedSwapTimeState,
  );
  const setEstimatedGasFee = useSetRecoilState(gasFeeEstimationState);
  const sourceNetwork = useRecoilValue(sourceNetworkState);
  const sourceToken = useRecoilValue(sourceTokenState);
  const destinationNetwork = useRecoilValue(destinationNetworkState);
  const destinationToken = useRecoilValue(destinationTokenState);
  // const destinationAddress = useRecoilValue(destinationAddressState);
  const isSwapping = useRecoilValue(isSwappingState);
  const tokenQuotationInfo = useRecoilValue(tokenQuotationInfoState);
  const [fetchingQuotation, setFetchingQuotation] = useRecoilState(
    fetchingQuotationState,
  );

  const { walletAddress, isConnected } = useWalletConnector();

  const { tokenBalance } = useTokenBalance(
    walletAddress,
    sourceToken?.tokenContractAddress,
    sourceToken?.isNative,
    sourceNetwork?.rpcUrl,
  );

  useEffect(() => {
    if (sourceNetwork?.chainId && destinationNetwork?.chainId) {
      fetchSwapEstimationTime();
    }
  }, [sourceNetwork, destinationNetwork]);

  const fetchSwapEstimationTime = async () => {
    try {
      const response = await swapServer.getEstimatedSwapTime();
      setEstimatedSwapTime(
        response.data.body.data.generalAnalytics.transactionTime,
      );
    } catch (error) {
      console.error("Error fetching estimated swap time:", error);
      if (error instanceof AxiosError) {
        setErrorMessage(error?.response?.data?.status?.message);
      } else if (error instanceof Error) {
        setErrorMessage(error?.message);
      }
    }
  };

  const handleSourceAmountAction = async (value: string) => {
    setEstimatedGasFee({});
    setAmount(value);
    searchParams.set("amount", value);
    navigate(`?${createSearchParams(searchParams)}`);
  };

  return (
    <>
      <Card variant="secondary" className="my-4 h-36 sm:h-28">
        <div className="relative">
          <div className="grid grid-cols-4 grid-rows-[0.8fr_1fr_0.5fr] items-center px-4 py-5 sm:py-0 sm:pt-0">
            <div className="row-start-1 pt-2 text-sm">{title}</div>
            <div className="col-span-3 row-start-1 sm:col-span-2 sm:row-start-2">
              <button
                className="flex w-full items-center justify-between rounded-xl border border-backgroundLight bg-backgroundDark p-2"
                onClick={() => setShowDialog(true)}
                disabled={isSwapping}
              >
                {(!IsObjectEmpty(sourceNetwork) &&
                  !IsObjectEmpty(sourceToken) &&
                  side === "source") ||
                (!IsObjectEmpty(destinationNetwork) &&
                  !IsObjectEmpty(destinationToken) &&
                  side === "destination") ? (
                  <div className="mx-auto flex items-center">
                    <div className="flex items-center">
                      <img
                        src={
                          side === "source"
                            ? sourceNetwork?.logo
                            : destinationNetwork?.logo
                        }
                        alt="network logo"
                        className="mr-2"
                        width={25}
                      />
                      <span className="w-full whitespace-nowrap text-sm">
                        {side === "source"
                          ? sourceNetwork?.name?.split(" ")[0]
                          : destinationNetwork?.name.split(" ")[0]}
                      </span>
                    </div>
                    <img
                      src={Icons.PathIcon}
                      alt="path icon"
                      className="mx-2 sm:mx-4"
                    />
                    <div className="flex items-center">
                      <img
                        src={
                          side === "source"
                            ? getTokenLogoByCABN(sourceToken)
                            : getTokenLogoByCABN(destinationToken)
                        }
                        alt="token logo"
                        className="mr-2"
                        width={25}
                      />
                      <span className="w-full whitespace-nowrap text-sm">
                        {side === "source"
                          ? getTokenSymbolByCABN(sourceToken)
                          : getTokenSymbolByCABN(destinationToken)}
                      </span>
                    </div>
                  </div>
                ) : (
                  <span className="w-full text-[12px] sm:text-sm">
                    Select Chain And Token
                  </span>
                )}
                <ReactIcons.RiArrowDropDownLine size={30} />
              </button>
            </div>
            {fetchingQuotation &&
            side === "destination" &&
            !tokenQuotationInfo?.destinationTokenQuotationInfo
              ?.destinationAmount ? (
              <div className="col-span-4 row-start-2 flex items-center text-4xl sm:col-span-2 sm:row-end-2 sm:ml-auto sm:pl-2">
                <img src={Gif.Loader} alt="loading" className="h-4 w-4" />
                <span className="ml-2 text-xs">Fetching Quote</span>
              </div>
            ) : (
              <InputField
                disabled={
                  side === "destination" || (side === "source" && isSwapping)
                }
                type="integer"
                inputMode="decimal"
                placeholder="0.0"
                value={
                  side === "source"
                    ? amount
                    : amount !== ""
                      ? quationAmount.formatAmountOutMin
                      : "0.0"
                }
                className="col-span-4 row-start-2 text-4xl sm:col-span-2 sm:row-end-2 sm:pl-2"
                inputFieldClassName="text-left w-full sm:text-right"
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  handleSourceAmountAction(e.target.value)
                }
              />
            )}
            {side === "source" &&
            tokenQuotationInfo?.sourceTokenQuotationInfo?.usdcAmount ? (
              <div className="col-span-1 row-start-3 flex w-full items-center text-xs text-backgroundLight sm:col-start-4 sm:row-start-1 sm:justify-end sm:pt-2">
                ~$
                {Number(
                  tokenQuotationInfo?.sourceTokenQuotationInfo?.usdcAmount,
                ).toFixed(2)}
              </div>
            ) : (
              side === "destination" &&
              tokenQuotationInfo?.destinationTokenQuotationInfo?.usdcAmount && (
                <div className="col-span-1 row-start-3 flex w-full items-center text-xs text-backgroundLight sm:col-start-4 sm:row-start-1 sm:justify-end sm:pt-2">
                  ~$
                  {Number(
                    tokenQuotationInfo?.destinationTokenQuotationInfo
                      ?.usdcAmount,
                  ).toFixed(2)}
                </div>
              )
            )}
            {!IsObjectEmpty(sourceNetwork) &&
              !IsObjectEmpty(sourceToken) &&
              side === "source" &&
              isConnected &&
              tokenBalance && (
                <div className="col-span-4 row-start-3 flex w-full items-center justify-end text-xs">
                  <p className="mr-2">
                    <span className="text-backgroundLight">Balance:</span>{" "}
                    <span className="">{Number(tokenBalance).toFixed(6)}</span>
                  </p>{" "}
                  <Button
                    disabled={isSwapping}
                    variant="tertiary"
                    title="MAX"
                    className=""
                    style={{ fontSize: "10px", padding: "0.1rem 0.5rem" }}
                    onClick={() => handleSourceAmountAction(tokenBalance)}
                  />
                </div>
              )}
          </div>
        </div>
      </Card>
      {showDialog ? (
        <SelectTokenDialog
          show={showDialog}
          onHide={() => setShowDialog(false)}
          direction={side}
        />
      ) : null}
    </>
  );
};

export default SwapDetailsSelectorCard;
