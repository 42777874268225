import React, { useState } from "react";
import { Button, Card, InputField } from "../../components/ui";
import { Gif, Icons, ReactIcons } from "../../assets";
import { useNavigate } from "react-router-dom";
import { useRecoilValue } from "recoil";
import { allNetworksListState } from "../../recoil/swap";
import * as SwapServerRequest from "../../api/SwapCrud";
import { authTokenState } from "../../recoil/auth";
import Dropdown from "react-dropdown";
import "react-dropdown/style.css";
import { getSupportLink } from "../../utils/constants";

const AbandonedTxPage = () => {
  const navigate = useNavigate();
  const authToken = useRecoilValue(authTokenState);
  const networkList = useRecoilValue(allNetworksListState);
  const [loading, setLoading] = useState<boolean>(false);
  const [txHash, setTxHash] = useState<string>("");
  const [network, setNetwork] = useState<any>();
  const [regeneratingSwapSystemStatus, setRegeneratingSwapSystemStatus] =
    useState<string>("");

  const handleSearchTransaction = async () => {
    try {
      let onChainStatus = "";
      let systemStatus = "";
      setLoading(true);
      while (systemStatus !== "swapWithdrawCompleted") {
        const tx = await SwapServerRequest.regenerateSwap(
          authToken,
          txHash,
          network.chainId,
        );
        onChainStatus = tx?.data?.body?.onChainStatus;
        systemStatus = tx?.data?.body?.systemStatus;
        setRegeneratingSwapSystemStatus(systemStatus);
        if (systemStatus === "swapWithdrawCompleted") {
          setLoading(false);
        }
        if (
          onChainStatus === "failed" ||
          systemStatus === "swapFailed" ||
          systemStatus === "swapWithdrawFailed" ||
          systemStatus === "generatorSignatureFailed" ||
          systemStatus === "validatorSignatureFailed" ||
          systemStatus === "masterValidationFailed"
        ) {
          break;
        }
        await new Promise((res) => setTimeout(res, 2000));
      }
    } catch (err) {

      setLoading(false);
    }
  };

  const handlePasteAction = async () => {
    const value = await navigator.clipboard.readText();
    setTxHash(value);
  };

  const getButtonVariant = () => {
    if (loading) return "tertiary";
    if (txHash && network) return "primary";
    return "tertiary";
  };

  const options = networkList.map((network: any) => {
    return {
      value: network,
      label: (
        <div className="flex cursor-pointer items-center">
          <img className="mx-2" src={network?.logo} width={15} height={15} />{" "}
          {network?.name} (chain ID: {network?.chainId})
        </div>
      ),
    };
  });

  return (
    <>
      <Card variant="primary">
        <header>
          <div className="text-base text-white">
            <div className="flex items-center">
              <ReactIcons.IoArrowBack
                size={20}
                className="cursor-pointer"
                onClick={() => navigate(-1)}
              />
              <span className="ml-2">Manage Swap Transaction</span>
            </div>
          </div>
        </header>
        <main>
          <section className="p-5">
            <div className="my-5">
              <div className="flex items-center">
                <label className="text-left text-sm font-thin text-white">
                  Enter Transaction Hash{" "}
                </label>
                <ReactIcons.RxQuestionMarkCircled
                  color="white"
                  className="ml-2"
                />
              </div>
              <InputField
                type="text"
                value={txHash}
                placeholder="transaction hash"
                className="ml-auto mt-2 bg-backgroundDark"
                inputFieldClassName="p-2 text-white rounded-md border border-[#6F767E80] w-full font-thin text-sm"
                postfix={
                  <img
                    src={Icons.PasteIcon}
                    className="h-5 w-5 cursor-pointer"
                    alt="search icon"
                    onClick={() => handlePasteAction()}
                  />
                }
                onChange={(e) => setTxHash(e.target.value)}
              />
            </div>
            <div className="my-5">
              <div className="flex items-center">
                <label className="text-left text-sm font-thin text-white">
                  Select Network Selection{" "}
                </label>
                <ReactIcons.RxQuestionMarkCircled
                  color="white"
                  className="ml-2"
                />
              </div>
              <Dropdown
                className="dropdown mt-2"
                options={options}
                onChange={(e: any) => setNetwork(e?.value)}
                placeholder="Select Network"
                placeholderClassName="text-backgroundLight rounded-md border border-[#6F767E80] w-full font-thin text-sm bg-backgroundDark p-2"
                menuClassName="!bg-backgroundDark !text-white font-thin text-sm !border-[#6F767E80]"
                controlClassName="!p-0 !border-none !bg-inherit"
              />
            </div>
            <Button
              variant={getButtonVariant()}
              className="mt-5 w-full text-lg"
              title="Search Transaction"
              onClick={() => handleSearchTransaction()}
              disabled={loading || !txHash || !network || !authToken}
              prefix={
                loading ? (
                  <img src={Gif.Loader} alt="loader" className="h-4 w-4" />
                ) : undefined
              }
            />
          </section>
        </main>
      </Card>
      <section className="mt-2">
        {txHash ? ( //regeneratingSwapSystemStatus &&
          <div className="mt-3 text-white">
            {regeneratingSwapSystemStatus === "swapWithdrawCompleted" ? (
              <div className="flex items-center">
                <img
                  src={Icons.CircularTickIcon}
                  color="green"
                  height={20}
                  width={20}
                  className="mr-2"
                />
                <span>Transaction Withdrawal Successful.</span>
              </div>
            ) : regeneratingSwapSystemStatus === "swapWithdrawPending" ||
              regeneratingSwapSystemStatus === "swapPending" ? (
              <>
                <div className="flex items-center">
                  <img
                    src={Icons.TimerIcon}
                    height={20}
                    width={20}
                    className="mr-2"
                  />
                  <span>Transaction Is Currently Being Processed.</span>
                </div>
                <p className="ml-3 mt-1 text-[12px]">
                  If transaction does not proceed in next 5 minutes, please
                  <a href={getSupportLink()} target="_blank">
                    {" "}
                    Contact Support
                  </a>
                </p>
              </>
            ) : regeneratingSwapSystemStatus === "swapFailed" ||
              regeneratingSwapSystemStatus === "swapWithdrawFailed" ? (
              <>
                <div className="flex items-center">
                  <img
                    src={Icons.HexagonWarningIcon}
                    height={20}
                    width={20}
                    className="mr-2"
                  />
                  <span>Transaction Is Failed Due to Some Reason.</span>
                </div>
                <p className="ml-3 mt-1 text-[12px]">
                  If transaction does not proceed in next 5 minutes, please
                  <a color="#dab46e" href={getSupportLink()} target="_blank">
                    {" "}
                    Contact Support
                  </a>
                </p>
              </>
            ) : null}
            <div className="ml-1 mt-2">
              {regeneratingSwapSystemStatus === "swapFailed" ? (
                <div className="mt-1 flex items-center text-[12px]">
                  <img
                    src={Icons.HexagonWarningIcon}
                    height={20}
                    width={20}
                    className="mr-2"
                  />
                  <span>Swap Failed Due to Some Reason.</span>
                </div>
              ) : regeneratingSwapSystemStatus === "swapPending" ? (
                <div className=" mt-1 flex items-center text-[12px]">
                  <img
                    src={Icons.TimerIcon}
                    height={20}
                    width={20}
                    className="mr-2"
                  />
                  <span>Transaction Swap In Progress.</span>
                </div>
              ) : regeneratingSwapSystemStatus === "swapCompleted" ? (
                <div className=" mt-1 flex items-center">
                  <img
                    src={Icons.CircularTickIcon}
                    height={20}
                    width={20}
                    className="mr-2"
                  />
                  <span>Transaction Swap Processed.</span>
                </div>
              ) : regeneratingSwapSystemStatus ===
                "generatorSignatureCreated" ||
                regeneratingSwapSystemStatus === "validatorSignatureCreated" ? (
                <>
                  <div className=" mt-1 flex items-center">
                    <img
                      src={Icons.PalindromeIcon}
                      height={20}
                      width={20}
                      className="mr-2"
                    />
                    <span>Withdrawal item Not Generated.</span>
                  </div>
                </>
              ) : regeneratingSwapSystemStatus === "swapWithdrawGenerated" ||
                regeneratingSwapSystemStatus === "swapWithdrawPending" ? (
                <>
                  <div className=" mt-1 flex items-center">
                    <img
                      src={Icons.CircularTickIcon}
                      height={20}
                      width={20}
                      className="mr-2"
                    />
                    <span>Transaction Swap Processed.</span>
                  </div>
                  <div className=" mt-1 flex items-center">
                    <img
                      src={Icons.CircularTickIcon}
                      height={20}
                      width={20}
                      className="mr-2"
                    />
                    <span>Withdrawal Item Generated.</span>
                  </div>
                  <div className=" mt-1 flex items-center">
                    <img
                      src={Icons.TimerIcon}
                      height={20}
                      width={20}
                      className="mr-2"
                    />
                    <span>Withdraw Is In Progress.</span>
                  </div>
                </>
              ) : regeneratingSwapSystemStatus === "swapWithdrawFailed" ? (
                <>
                  <div className=" mt-1 flex items-center">
                    <img
                      src={Icons.CircularTickIcon}
                      height={20}
                      width={20}
                      className="mr-2"
                    />
                    <span>Transaction Swap Processed.</span>
                  </div>
                  <div className=" mt-1 flex items-center">
                    <img
                      src={Icons.CircularTickIcon}
                      height={20}
                      width={20}
                      className="mr-2"
                    />
                    <span>Withdrawal Item Generated.</span>
                  </div>
                  <div className=" mt-1 flex items-center">
                    <img
                      src={Icons.HexagonWarningIcon}
                      height={20}
                      width={20}
                      className="mr-2"
                    />
                    <span>Withdraw Failed</span>
                  </div>
                </>
              ) : regeneratingSwapSystemStatus === "swapWithdrawCompleted" ? (
                <>
                  <div className=" mt-1 flex items-center">
                    <img
                      src={Icons.CircularTickIcon}
                      height={20}
                      width={20}
                      className="mr-2"
                    />
                    <span>Transaction Swap Processed.</span>
                  </div>
                  <div className=" mt-1 flex items-center">
                    <img
                      src={Icons.CircularTickIcon}
                      height={20}
                      width={20}
                      className="mr-2"
                    />
                    <span>Withdrawal Item Generated.</span>
                  </div>
                  <div className=" mt-1 flex items-center">
                    <img
                      src={Icons.CircularTickIcon}
                      height={20}
                      width={20}
                      className="mr-2"
                    />
                    <span>Swap Withdrawn</span>
                  </div>
                </>
              ) : null}
            </div>
          </div>
        ) : null}
      </section>
    </>
  );
};

export default AbandonedTxPage;
