import { Button, Dialog } from "./ui";
import { Gif, Icons } from "../assets";
import { useEffect, useState } from "react";
import { DEFAULT_TOKEN_LOGO, TOKENS } from "../utils/constants";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import {
  allNetworksListState,
  allTokensListState,
  connectedNetworkState,
  destinationNetworkState,
  ErrorMessagesState,
  sourceNetworkState,
} from "../recoil/swap";
import {
  createSearchParams,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import Web3 from "web3";
import { IsObjectEmpty } from "../utils/helper";
import { useTokenBalances } from "../hooks";
import { useWalletConnector } from "foundry";
import { addTokenToMetaMask, Web3Helper } from "../utils/Web3Helper";
import { authTokenState } from "../recoil/auth";
import * as TokenServerRequest from "../api/TokenCrud";
import { AxiosError } from "axios";

type Direction = "source" | "destination";

const SortedNetworks = [
  "Arbitrum",
  "BSC",
  "Ethereum",
  "Scroll",
  "Base",
  "zkSync",
  "Avalanche C-Chain",
  "Optimism",
];

const SelectTokenDialog = ({
  show,
  onHide,
  direction,
}: {
  show: boolean;
  onHide: () => void;
  direction: Direction;
}) => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const allNetworksList = useRecoilValue(allNetworksListState);
  const authToken = useRecoilValue(authTokenState);
  const connectedNetwork = useRecoilValue(connectedNetworkState);
  const sourceNetwork = useRecoilValue(sourceNetworkState);
  const destinationNetwork = useRecoilValue(destinationNetworkState);
  const setErrorMessage = useSetRecoilState(ErrorMessagesState);
  const [tokens, setTokens] = useRecoilState<any>(allTokensListState);
  const [selectedNetwork, setSelectedNetwork] = useState(connectedNetwork);
  const [searchQuery, setSearchQuery] = useState("");
  const [filteredTokens, setFilteredTokens] = useState<any[]>([]);
  const [tokensData, setTokensData] = useState<any[]>([]);
  const [fetchingTokenData, setFetchingTokenData] = useState(false);
  const [fetchingImportedToken, setFetchingImportedToken] = useState(false);
  const [importedToken, setImportedToken] = useState<any>({});
  const { walletAddress } = useWalletConnector();
  const { tokenBalances } = useTokenBalances(
    walletAddress,
    tokens,
    selectedNetwork,
  );
  console.log(tokens, "tokensData")
  const allNetworks =
    allNetworksList && allNetworksList.length
      ? [...allNetworksList].sort((a: any, b: any) => {
        return (
          SortedNetworks.indexOf(a.name) - SortedNetworks.indexOf(b.name)
        );
      })
      : [];

  useEffect(() => {
    if (
      connectedNetwork &&
      IsObjectEmpty(selectedNetwork) &&
      IsObjectEmpty(sourceNetwork) &&
      IsObjectEmpty(destinationNetwork)
    ) {
      setSelectedNetwork(connectedNetwork);
    } else if (
      connectedNetwork &&
      !IsObjectEmpty(sourceNetwork) &&
      !IsObjectEmpty(destinationNetwork)
    ) {
      setSelectedNetwork(
        direction === "source" ? sourceNetwork : destinationNetwork,
      );
    }
  }, [connectedNetwork, sourceNetwork, destinationNetwork]);

  useEffect(() => {


    const tokensData = getSortedTokenList(
      searchQuery ? filteredTokens : tokens,
      tokenBalances,
      selectedNetwork,
    );
    setTokensData(tokensData);
  }, [filteredTokens, tokens, tokenBalances, selectedNetwork]);

  const importTokenHandler = async (tokenData: any) => {
    // try {
    //   setFetchingImportedToken(true);
    //   const response = await TokenServerRequest.createUserCustomCABN(
    //     tokenData,
    //     authToken,
    //   );
    //   if (response.data) {
    //     const tokens = await fetchTokens();
    //     handleSearchQuery(tokenData?.tokenAddress, tokens);
    //     setFetchingImportedToken(false);
    //   }
    // } catch (error) {
    //   console.error("Error importing token:", error);
    //   setFetchingImportedToken(false);
    // }
  };

  // const fetchTokens = async () => {
  //   try {
  //     const response = await TokenServerRequest.getCABNsList({}, authToken);
  //     setTokens([
  //       ...response?.data?.body?.currencyAddressesByNetworks,
  //       ...TOKENS,
  //     ]);
  //     setImportedToken({});
  //     return [...response?.data?.body?.currencyAddressesByNetworks, ...TOKENS];
  //   } catch (error) {
  //     console.error("Error fetching networks:", error);
  //     if (error instanceof AxiosError) {
  //       setErrorMessage(error?.response?.data?.status?.message);
  //     } else if (error instanceof Error) {
  //       setErrorMessage(error?.message);
  //     }
  //   }
  // };

  const handleTokenSelection = (token: any, network: any) => {
    if (network) {
      searchParams.set(
        direction === "source" ? "fromChainId" : "toChainId",
        network?.chainId,
      );
    }
    if (token) {
      searchParams.set(
        direction === "source" ? "fromToken" : "toToken",
        token?.tokenContractAddress,
      );
      searchParams.set(
        direction === "source" ? "sourceSlippage" : "destinationSlippage",
        direction === "source"
          ? token?.sourceSlippage === undefined
            ? "2"
            : token?.sourceSlippage
          : token?.destinationSlippage === undefined
            ? "2"
            : token?.destinationSlippage,
      );
    }
    setSearchQuery("");
    navigate(`?${createSearchParams(searchParams)}`);
    onHide();
  };

  const fetchTokenData = async (tokenAddress: string, selectedNetwork: any) => {
    setFetchingTokenData(true);
    const web3 = new Web3(selectedNetwork?.rpcUrl);
    const web3Helper = new Web3Helper(web3);
    const token = await web3Helper.fetchTokenDetail(tokenAddress);
    if (!token?.name && tokenAddress !== token?.address) {
      setImportedToken({});
    }
    setImportedToken({ ...token, chainId: selectedNetwork?.chainId });
    setFetchingTokenData(false);
  };

  const getSortedTokenList = (
    tokens: any[],
    tokenBalances: any,
    selectedNetwork: any,
  ) => {
    let tokensData: any = [];
    let balanceTokens: any[] = [];
    let priorityTokens: any[] = [];
    if (tokens?.length) {
      Promise.all(
        tokens.map(async (token: any) => {
          if (
            token?.network?.chainId === selectedNetwork.chainId &&
            token?.isAllowedOnMultiSwap
          ) {
            let balance: any;
            if (token?.priority > 0) {
              priorityTokens.push({
                ...token,
                balance:
                  tokenBalances[
                  `${token?.network?.chainId}:${token?.tokenContractAddress}`
                  ],
              });
            } else if (
              tokenBalances[
              `${token?.network?.chainId}:${token?.tokenContractAddress}`
              ]
            ) {
              balanceTokens.push({
                ...token,
                balance:
                  tokenBalances[
                  `${token?.network?.chainId}:${token?.tokenContractAddress}`
                  ],
              });
            } else {
              tokensData.push({
                ...token,
                balance,
              });
            }
          }
        }),
      );
      tokensData = [
        ...priorityTokens.sort((a, b) => a.priority - b.priority),
        ...balanceTokens,
        ...tokensData.sort((a: any, b: any) => {
          const symbolA = a?.currency?.symbol.toLowerCase();
          const symbolB = b?.currency?.symbol.toLowerCase();
          if (symbolA < symbolB) {
            return -1;
          }
          if (symbolA > symbolB) {
            return 1;
          }
          return 0;
        }),
      ];
    }
    return tokensData;
  };

  const handleSearchQuery = (query: string, tokens: any[]) => {
    setImportedToken({});
    setSearchQuery(query);
    const matchedTokens = tokens.filter((token: any) => {
      return (
        token.network?.chainId === selectedNetwork?.chainId &&
        (token?.currency?.name.toLowerCase().includes(query.toLowerCase()) ||
          token?.currency?.symbol.toLowerCase().includes(query.toLowerCase()) ||
          token?.tokenContractAddress
            .toLowerCase()
            .includes(query.toLowerCase()) ||
          token?.tokenContractAddress.toLowerCase() === query.toLowerCase())
      );
    });
    if (
      query &&
      Web3.utils.isAddress(query) &&
      !matchedTokens.length &&
      authToken
    ) {
      fetchTokenData(query, selectedNetwork);
    }
    setFilteredTokens(matchedTokens);
  };

  const ImportTokenButton = ({ tokenData }: any) => {
    return (
      <div className="mt-2 flex h-[400px] w-full flex-col overflow-auto sm:mt-0 sm:h-[360px] sm:w-[390px]">
        <div className="flex cursor-pointer items-center rounded-md bg-[#11131580] p-4 hover:bg-backgroundDark">
          <div className="flex h-[30px] w-5/6 items-center justify-between">
            <img
              src={DEFAULT_TOKEN_LOGO}
              alt="token"
              className="h-[30px] w-[30px]"
            />
            <div className="ml-2 mr-auto flex flex-col">
              <p className="text-sm font-bold text-white">
                {tokenData?.symbol}
              </p>
              <span className=" whitespace-nowrap text-[8.5px] font-normal text-backgroundLight">
                {tokenData?.name}
              </span>
            </div>
          </div>
          {fetchingImportedToken ? (
            <img src={Gif.Loader} className="h-4 w-4" />
          ) : (
            <Button
              variant="primary"
              title={"Import"}
              rounded="lg"
              className="h-[30px] !px-2 !py-0"
              onClick={() => importTokenHandler(tokenData)}
            />
          )}
        </div>
      </div>
    );
  };

  return (
    <Dialog
      show={show}
      onHide={onHide}
      title="Select Token"
      showCloseButton
      size="lg"
    >
      <div className="relative my-2">
        <span className="absolute inset-y-0 left-2 flex items-center">
          <img src={Icons.SearchIcon} alt="search icon" />
        </span>
        <input
          className="w-full rounded-md border-[1px] border-backgroundLight bg-background p-1 py-2 pl-9 pr-3 text-[12px] font-normal text-backgroundLight focus:outline-none"
          type="text"
          src={Icons.CrossIcon}
          placeholder={`Search Token or paste address`}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            handleSearchQuery(e.target.value, tokens)
          }
        />
      </div>
      <div className="mt-3 flex flex-col flex-wrap justify-between sm:flex-row">
        <div
          className="grid w-full grid-flow-col grid-cols-none gap-1 overflow-y-scroll bg-[#11131580] sm:w-[135px] sm:grid-flow-row sm:grid-cols-2 sm:grid-rows-5"
          style={{ scrollbarWidth: "none" }}
        >
          {allNetworks.map((network: any, index: any) => (
            <div
              className={`flex h-16 w-16 cursor-pointer flex-col items-center justify-center hover:bg-backgroundDark sm:h-16 sm:justify-center ${network?.chainId === selectedNetwork?.chainId ? "bg-backgroundDark" : ""}`}
              style={{
                scrollbarWidth: "none",
                msOverflowStyle: "none",
              }}
              key={index}
              onClick={() => {
                setSelectedNetwork(network);
                setSearchQuery("");
              }}
            >
              <img src={network?.logo} alt="network" width={24} height={24} />
              <div className="mt-2 whitespace-nowrap text-center text-[10px] text-white">
                {network?.name?.split(" ")[0]}
              </div>
            </div>
          ))}
        </div>
        <div
          className="mt-2 flex h-[400px] w-full flex-col overflow-auto sm:mt-0 sm:h-[360px] sm:w-[390px]"
          style={{ scrollbarWidth: "none" }}
        >
          {fetchingImportedToken || fetchingTokenData ? (
            <img src={Gif.Loader} className="mx-auto h-8 w-8" />
          ) : !IsObjectEmpty(selectedNetwork) &&
            searchQuery &&
            Web3.utils.isAddress(searchQuery) &&
            importedToken?.name ? (
            <ImportTokenButton tokenData={importedToken} />
          ) : !IsObjectEmpty(selectedNetwork) && tokensData.length ? (
            tokensData
              .filter(
                (token: any) =>
                  token?.network?.chainId === selectedNetwork?.chainId,
              )
              .map((token: any, index: any) => (
                <div
                  className="flex cursor-pointer items-center rounded-md bg-[#11131580] p-4 hover:bg-backgroundDark"
                  key={index}
                >
                  <div
                    className="flex h-[30px] w-5/6 items-center justify-between"
                    onClick={() => handleTokenSelection(token, selectedNetwork)}
                  >
                    <img
                      src={
                        token?.isDefault
                          ? token?.currency?.logo
                          : DEFAULT_TOKEN_LOGO
                      }
                      alt="token"
                      className="h-[30px] w-[30px]"
                    />
                    <div className="ml-2 flex flex-col">
                      <p className="text-sm font-bold text-white">
                        {token?.isDefault
                          ? token?.currency?.symbol
                          : token?.nonDefaultCurrencyInformation?.symbol}
                      </p>
                      <span className=" whitespace-nowrap text-[8.5px] font-normal text-backgroundLight">
                        {token?.isDefault
                          ? token?.currency?.name
                          : token?.nonDefaultCurrencyInformation?.name}
                      </span>
                    </div>
                    <div className="ml-auto">
                      {token?.balance ? (
                        <span className="text-xs font-bold text-white">
                          <span className="text-backgroundLight">
                            Balance:{" "}
                          </span>
                          {Number(token?.balance)
                            ? Number(token?.balance).toFixed(4)
                            : " "}
                        </span>
                      ) : null}
                    </div>
                  </div>
                  <div className="flex h-4 w-1/6 items-center justify-end gap-2">
                    <img
                      src={Icons.FaRegStar}
                      alt="favorite icon"
                      className={`${token?.isNative ? "mx-auto" : ""} h-4 w-4 cursor-not-allowed`}
                    />
                    {!token?.isNative ? (
                      <img
                        src={Icons.AddCircleOutline}
                        alt="add token icon"
                        className="h-4 w-4"
                        onClick={() =>
                          addTokenToMetaMask(
                            token?.tokenContractAddress,
                            token?.currency?.symbol,
                            token?.decimals,
                            token?.currency?.logo,
                          )
                        }
                      />
                    ) : null}
                  </div>
                </div>
              ))
          ) : (
            <div className="text-center text-white">
              <div>Nothing to Show</div>
              {!walletAddress &&
                searchQuery &&
                Web3.utils.isAddress(searchQuery) ? (
                <div>Connect Wallet for Importing Token</div>
              ) : null}
            </div>
          )}
        </div>
      </div>
    </Dialog>
  );
};

export default SelectTokenDialog;
