import React, { useEffect, useState } from "react";
import { Card, Button, InputField } from "../../components/ui";
import { Gif, Icons, Images, ReactIcons } from "../../assets";
import SwapDetailsSelectorCard from "./SwapDetailsSelectorCard";
import SlippageDialog from "../../components/SlippageDialog";
import {
  createSearchParams,
  useNavigate,
  useSearchParams,
} from "react-router-dom";

import ConnectWalletDialog from "../../components/ConnectWalletDialog";
import SwapFeeDetailCard from "./SwapFeeDetailCard";
import { Tooltip } from "react-tooltip";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import {
  allowanceState,
  destinationAddressState,
  destinationNetworkState,
  destinationTokenState,
  isApprovingState,
  estimatedSwapTimeState,
  fetchingGasFeeEstimationState,
  fetchingQuotationState,
  gasFeeEstimationState,
  isSwappingState,
  sourceNetworkState,
  sourceTokenState,
  swapAndWithdrawTxState,
  swapAndWithdrawTxStatusState,
  tokenQuotationInfoState,
  withdrawTxHashState,
  ErrorMessagesState,
  swapTxHashState,
  isCCTPState,
  infoMessagesState,
  isStargateState,
  amountState,
} from "../../recoil/swap";

import {
  useWalletConnector,
  useFoundrySendTransaction,
  useSwitchNetwork,
} from "foundry";
import ApproveRouterJson from "../../utils/abi/ApproveRouterAbi.json";
import SwapRouterJson from "../../utils/abi/swap.json";
import AmountOutJson from "../../utils/abi/AmountOutAbi.json";
import ReservesJson from "../../utils/abi/reservesAbi.json";
import erc20 from "../../utils/abi/erc20.json";
import { AbiItem } from "web3-utils";
import ApproveCard from "./ApproveCard";
import { IsObjectEmpty } from "../../utils/helper";
import { Web3Helper } from "../../utils/Web3Helper";
// import * as swapServerRequest from "../../api/SwapCrud";
import Big from "big.js";
import Web3, { providers } from "web3";
import { authTokenState } from "../../recoil/auth";
import {
  SwapAndWithdrawTxStatuses,
  FailedStatuses,
  SwapAndWithdrawErrorMessages,
  getSwapFailedHelpLink,
  getSwapInsufficientFundsHelpLink,
  getTransactionInfoMessage,
  LIQUIDITY_POOLS,
} from "../../utils/constants";
import { AxiosError } from "axios";
import { useTokenBalance } from "../../hooks";
import SwapPlatformFeeCard from "./SwapPlatformFeeCard";
import { ethers, JsonRpcProvider } from "ethers";
declare global {
  interface Window {
    ethereum?: any;
  }
}
const SwapCard = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const amount = searchParams.get("amount");
  const authToken = useRecoilValue(authTokenState);
  const sourceNetwork = useRecoilValue(sourceNetworkState);
  const sourceToken = useRecoilValue(sourceTokenState);
  const destinationNetwork = useRecoilValue(destinationNetworkState);
  const destinationToken = useRecoilValue(destinationTokenState);
  const estimatedSwapTime = useRecoilValue(estimatedSwapTimeState);
  const [errorMessage, setErrorMessage] = useRecoilState(ErrorMessagesState);
  const setInfoMessage = useSetRecoilState(infoMessagesState);
  const [isSwapping, setIsSwapping] = useRecoilState(isSwappingState);
  const [swapTxStatus, setSwapTxStatus] = useState<number>(0);
  const setAmount = useSetRecoilState(amountState);

  const [tokenQuotationInfo, setTokenQuotationInfo] = useRecoilState(
    tokenQuotationInfoState,
  );
  const [destinationAddress, setDestinationAddress] = useRecoilState(
    destinationAddressState,
  );

  const [fetchingQuotation, setFetchingQuotation] = useRecoilState(
    fetchingQuotationState,
  );
  const [fetchingGasFeeEstimation, setFetchingGasFeeEstimation] =
    useRecoilState(fetchingGasFeeEstimationState);
  const [estimatedGasFee, setEstimatedGasFee] = useRecoilState(
    gasFeeEstimationState,
  );
  const [swapAndWithdrawTxStatus, setSwapAndWithdrawTxStatus] = useRecoilState(
    swapAndWithdrawTxStatusState,
  );
  const [swapHash, setSwapHash] = useRecoilState(swapTxHashState);
  const [withdrawTxHash, setWithdrawTxHash] =
    useRecoilState(withdrawTxHashState);
  const [isApproving, setIsApproving] = useRecoilState(isApprovingState);
  const [allowance, setAllowance] = useRecoilState(allowanceState);
  const [showAddressField, setShowAddressField] = useState<boolean>(false);
  const [showDialog, setShowDialog] = useState<boolean>(false);
  const [showSlippage, setShowSlippage] = useState(false);
  const [quotationAmount, setQuotationAmount] = useState({});

  const { isConnected, walletAddress, currentNetworkChainId } =
    useWalletConnector();
  const { switchWeb3Network } = useSwitchNetwork();
  const { tokenBalance } = useTokenBalance(
    walletAddress,
    sourceToken?.tokenContractAddress,
    sourceToken?.isNative,
    sourceNetwork?.rpcUrl,
  );
  const [isCCTP, setIsCCTP] = useRecoilState(isCCTPState);
  const [isStargate, setIsStargate] = useRecoilState(isStargateState);
  const { hash, reciept, status, error, reset, sendWeb3Transaction } =
    useFoundrySendTransaction();
  const {
    hash: swapTxHash,
    reciept: swapTxReceipt,
    status: swapStatus,
    error: swapTxError,
    reset: swapTxReset,
    sendWeb3Transaction: sendSwapTransaction,
  } = useFoundrySendTransaction();

  // console.log({ sourceToken });

  useEffect(() => {
    if (
      !IsObjectEmpty(sourceNetwork) &&
      !IsObjectEmpty(sourceToken) &&
      walletAddress &&
      !sourceToken?.isNative
    ) {
      setEstimatedGasFee({});
      getCurrentApprovedAmount(
        sourceToken.tokenContractAddress,
        sourceNetwork.multiSwapFiberRouterSmartContractAddress,
        walletAddress,
        sourceNetwork.rpcUrl,
      );
    }
    if (
      !IsObjectEmpty(sourceNetwork) &&
      !IsObjectEmpty(sourceToken) &&
      walletAddress &&
      reciept?.status === "success" &&
      isApproving &&
      tokenQuotationInfo?.sourceTokenQuotationInfo &&
      !IsObjectEmpty(tokenQuotationInfo?.sourceTokenQuotationInfo) &&
      tokenQuotationInfo?.destinationTokenQuotationInfo &&
      !IsObjectEmpty(tokenQuotationInfo?.destinationTokenQuotationInfo) &&
      tokenQuotationInfo?.fakeDestinationTokenQuotationInfo &&
      !IsObjectEmpty(tokenQuotationInfo?.fakeDestinationTokenQuotationInfo)
    ) {
      reset();
      setIsApproving(false);
      setIsSwapping(false);
      if (sourceToken?.isNative) {
        getCurrentApprovedAmount(
          sourceToken.tokenContractAddress,
          sourceNetwork.multiSwapFiberRouterSmartContractAddress,
          walletAddress,
          sourceNetwork.rpcUrl,
        );
      }
      // fetchGasFeeEstimationAction(
      //   walletAddress,
      //   sourceNetwork?.chainId,
      //   sourceToken?.tokenContractAddress,
      //   tokenQuotationInfo,
      //   destinationAddress,
      //   destinationNetwork?.chainId,
      //   destinationToken?.tokenContractAddress,
      //   isCCTP,
      //   isStargate,
      // );
    }
  }, [
    hash,
    isApproving,
    reciept?.status,
    sourceNetwork,
    sourceToken,
    tokenQuotationInfo,
  ]);

  useEffect(() => {
    if (hash && isApproving && status === "success") {
      setIsApproving(false);
      // handleQuotationAction(
      //   walletAddress,
      //   sourceNetwork?.chainId,
      //   sourceToken?.tokenContractAddress,
      //   tokenQuotationInfo?.sourceTokenQuotationInfo?.sourceAmount,
      //   destinationAddress,
      //   destinationNetwork?.chainId,
      //   destinationToken?.tokenContractAddress,
      //   searchParams.get("referralCode")!,
      //   false,
      // );
    }
    // if (hash && isSwapping && authToken) {
    //   handleSwapAndWithdrawAction(
    //     hash,
    //     sourceNetwork?._id,
    //     sourceToken?._id,
    //     tokenQuotationInfo,
    //     destinationNetwork?._id,
    //     destinationToken?._id,
    //     estimatedGasFee,
    //     authToken,
    //     isCCTP,
    //     isStargate,
    //   );
    // }
  }, [hash, isSwapping, isApproving, status]);

  useEffect(() => {
    if ((error as any)?.shortMessage) {
      setIsSwapping(false);
      setIsApproving(false);
      setErrorMessage((error as any)?.shortMessage);
    }
  }, [error]);

  useEffect(() => {
    if (isConnected) {
      setShowDialog(false);
    }
  }, [isConnected]);

  useEffect(() => {
    if (amount && Number(amount) > 0 && sourceToken && destinationToken) {
      fetchQuotation(
        amount,
        sourceNetwork,
        destinationNetwork,
        sourceToken,
        destinationToken,
      );
    }
  }, [amount, sourceToken, destinationToken]); // Call fetchQuotation when any of these change

  const switchSelections = () => {
    setEstimatedGasFee({});
    searchParams.set("fromChainId", destinationNetwork?.chainId);
    searchParams.set("toChainId", sourceNetwork?.chainId);
    searchParams.set("fromToken", destinationToken?.tokenContractAddress);
    searchParams.set("toToken", sourceToken?.tokenContractAddress);
    navigate(`?${createSearchParams(searchParams)}`);
  };

  const getTotalEstimatedGasFee = () => {
    if (estimatedGasFee?.destination?.gasPrice) {
      return new Big(Number(estimatedGasFee?.source?.gasPrice))
        .add(Number(estimatedGasFee?.destination?.gasPrice))
        .toFixed();
    } else if (estimatedGasFee?.source?.gasPrice) {
      return estimatedGasFee?.source?.gasPrice;
    }
    return undefined;
  };

  const handleDestinationAddressAction = (
    e: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setDestinationAddress(e.target.value);
    searchParams.set("to", e.target.value);
    navigate(`?${createSearchParams(searchParams)}`);
  };

  const handlePasteAction = async () => {
    const value = await navigator.clipboard.readText();
    setDestinationAddress(value);
    searchParams.set("to", value);
    navigate(`?${createSearchParams(searchParams)}`);
    // setShowAddressField(false);
  };

  const getCurrentApprovedAmount = async (
    sourceTokenContractAddress: string,
    fiberRouterContractAddress: string,
    walletAddress: string,
    sourceNetworkRpcUrl: string,
  ) => {
    const web3 = new Web3(sourceNetworkRpcUrl);
    const web3Helper = new Web3Helper(web3);
    const response = await web3Helper.getStandardContractAllocation(
      sourceTokenContractAddress,
      walletAddress,
      fiberRouterContractAddress,
    );
    setAllowance(response?.allowance);
  };

  const approveContractAllocation = async (
    tokenContractAddress: string,
    walletAddress: string,
    fiberRouterSmartContractAddress: string,
    web3Sdk: any,
    amount: Big,
    sendWeb3Transaction: any,
  ) => {
    try {
      setIsApproving(true);
      const contractInstance = new web3Sdk.eth.Contract(
        ApproveRouterJson.abi as AbiItem[],
        tokenContractAddress,
      );
      const decimals = await contractInstance.methods.decimals().call();
      const payload = {
        from: walletAddress,
        to: tokenContractAddress,
        data: contractInstance.methods
          .approve(
            fiberRouterSmartContractAddress,
            new Big(amount)
              .times(10 ** Number(decimals))
              .toFixed()
              .toString(),
          )
          .encodeABI(),
      };
      return sendWeb3Transaction(payload);
    } catch (error) {
      console.log("approving", error);
      setIsApproving(false);
      if (error instanceof Error) {
        setErrorMessage(error?.message);
      }
    }
  };

  const fetchQuotation = async (
    amount: string,
    sourceNetwork: any,
    destNetwork: any,
    sourceToken: any,
    destToken: any,
  ) => {
    try {
      // Set up the necessary providers
      const baseRpcUrl =
        "https://base-mainnet.core.chainstack.com/e7aa01c976c532ebf8e2480a27f18278";
      const baseProvider = new ethers.JsonRpcProvider(baseRpcUrl);
      const sourceNetworkRpcUrl = sourceNetwork?.rpcUrl;
      const sourceProvider = new ethers.JsonRpcProvider(sourceNetworkRpcUrl);
      const destNetworkRpcUrl = destNetwork?.rpcUrl;
      const destNetworkProvider = new ethers.JsonRpcProvider(destNetworkRpcUrl);

      // Get reserves from Base pool
      const reservesContract = new ethers.Contract(
        sourceToken?.currency?.symbol === "x-OFT"
          ? LIQUIDITY_POOLS[sourceToken?.currency?.symbol]
          : destinationToken?.currency?.symbol === "x-OFT"
            ? LIQUIDITY_POOLS[destinationToken?.currency?.symbol]
            : sourceToken?.currency?.symbol === "x-ITS"
              ? LIQUIDITY_POOLS[sourceToken?.currency?.symbol]
              : destinationToken?.currency?.symbol === "x-ITS"
                ? LIQUIDITY_POOLS[destinationToken?.currency?.symbol]
                : "",
        // "0x2f9c29a605a9d3cdd79382345e40efeda7bf727c", // Reserves contract address on Base
        ReservesJson,
        baseProvider,
      );

      const reserves = await reservesContract.getReserves();
      console.log({
        reserves,
      });
      // Calculate the amount out using Base chain
      const amountOutContract = new ethers.Contract(
        "0x4752ba5dbc23f44d87826276bf6fd6b1c372ad24", // Router contract on Base
        AmountOutJson,
        baseProvider,
      );
      // Fetch token decimals dynamically
      const sourceTokenContract = new ethers.Contract(
        sourceToken?.tokenContractAddress,
        erc20.abi,
        sourceProvider,
      );
      const destTokenContract = new ethers.Contract(
        destToken?.tokenContractAddress,
        erc20.abi,
        destNetworkProvider,
      );

      const sourceDecimals = await sourceTokenContract.decimals();
      const destDecimals = await destTokenContract.decimals();

      // Parse the input amount based on the source token's decimals
      const formatAmountIn = ethers.parseUnits(amount, sourceDecimals);

      // Determine the appropriate reserves based on the token being swapped

      let amountInput;
      let amountOutput;
      if (sourceToken?.currency?.symbol === "x-OFT") {
        amountInput = reserves[0];
        amountOutput = reserves[1];
      } else if (destinationToken?.currency?.symbol === "x-OFT") {
        amountInput = reserves[1];
        amountOutput = reserves[0];
      } else if (sourceToken?.currency?.symbol === "x-ITS") {
        amountInput = reserves[1];
        amountOutput = reserves[0];
      } else if (destinationToken?.currency?.symbol === "x-ITS") {
        amountInput = reserves[0];
        amountOutput = reserves[1];
      }
      console.log(
        formatAmountIn,
        amountInput, // Use appropriate reserve for x-OFT/USDC
        amountOutput,
      );
      // Get the output amount based on the swap direction
      const amountOut = await amountOutContract.getAmountOut(
        formatAmountIn,
        amountInput, // Use appropriate reserve for x-OFT/USDC
        amountOutput,
      );
      console.log({ amountOut, destDecimals });
      // Format the output amount using the destination token's decimals
      const formatAmountOutMin = ethers.formatUnits(amountOut, destDecimals);
      console.log({ formatAmountOutMin });
      // Set the quotation result (can be used to display or for further processing)
      setQuotationAmount({ formatAmountOutMin, amountOut, amount });
    } catch (error) {
      console.error("Error fetching quotation:", error);
      if ((error as any)?.shortMessage) {
        setIsSwapping(false);
        setIsApproving(false);
        setErrorMessage((error as any)?.shortMessage);
      } else {
        // Handle errors and optionally display a user-friendly message
        setErrorMessage("Failed to fetch the quotation. Please try again.");
      }
    }
  };

  // console.log({ quotationAmount });

  const sendSwapTx = async (
    walletAddress: string,
    fiberRouterSmartContractAddress: string,
    amount: any,
    sourceTokenAddress: string,
    destTokenAddress: string,
  ) => {
    try {
      setIsSwapping(true);
      const sourceNetworkRpcUrl = sourceNetwork?.rpcUrl;
      const sourceProvider = new ethers.JsonRpcProvider(sourceNetworkRpcUrl);

      const sourceNetworkProvider = new ethers.BrowserProvider(window.ethereum); // Arbitrum provider
      const signer = await sourceNetworkProvider.getSigner(walletAddress);

      const baseRpcUrl =
        "https://base-mainnet.core.chainstack.com/e7aa01c976c532ebf8e2480a27f18278"; // Arbitrum provider
      const baseProvider = new ethers.JsonRpcProvider(baseRpcUrl);

      const destNetworkRpcUrl = destinationNetwork?.rpcUrl;
      const destNetworkProvider = new ethers.JsonRpcProvider(destNetworkRpcUrl);

      // Get reserves from Base pool
      const reservesContract = new ethers.Contract(
        sourceToken?.currency?.symbol === "x-OFT"
          ? LIQUIDITY_POOLS[sourceToken?.currency?.symbol]
          : destinationToken?.currency?.symbol === "x-OFT"
            ? LIQUIDITY_POOLS[destinationToken?.currency?.symbol]
            : sourceToken?.currency?.symbol === "x-ITS"
              ? LIQUIDITY_POOLS[sourceToken?.currency?.symbol]
              : destinationToken?.currency?.symbol === "x-ITS"
                ? LIQUIDITY_POOLS[destinationToken?.currency?.symbol]
                : "",
        // "0x2f9c29a605a9d3cdd79382345e40efeda7bf727c", // Reserves contract address on Base
        ReservesJson,
        baseProvider,
      );
      const reserves = await reservesContract.getReserves();

      // Calculate the amount out using Base chain
      const amountOutContract = new ethers.Contract(
        "0x4752ba5dbc23f44d87826276bf6fd6b1c372ad24", // Router contract on Base
        AmountOutJson,
        baseProvider,
      );
      console.log({ amountOutContract });

      const sourceTokenContract = new ethers.Contract(
        sourceTokenAddress,
        erc20.abi,
        sourceProvider,
      );
      const destTokenContract = new ethers.Contract(
        destTokenAddress,
        erc20.abi,
        destNetworkProvider,
      );

      const sourceDecimals = await sourceTokenContract.decimals();
      const destDecimals = await destTokenContract.decimals();

      const formatAmountIn = ethers.parseUnits(amount, sourceDecimals);

      console.log(formatAmountIn, reserves[0], reserves[1]);
      // Determine the appropriate reserves based on the token being swapped
      let amountInput;
      let amountOutput;
      if (sourceToken?.currency?.symbol === "x-OFT") {
        amountInput = reserves[0];
        amountOutput = reserves[1];
      } else if (destinationToken?.currency?.symbol === "x-OFT") {
        amountInput = reserves[1];
        amountOutput = reserves[0];
      } else if (sourceToken?.currency?.symbol === "x-ITS") {
        amountInput = reserves[1];
        amountOutput = reserves[0];
      } else if (destinationToken?.currency?.symbol === "x-ITS") {
        amountInput = reserves[0];
        amountOutput = reserves[1];
      }
      // USDC - x-OFT
      console.log(
        sourceToken,
        formatAmountIn,
        amountInput, // Use appropriate reserve for x-OFT/USDC
        amountOutput,
      );
      const amountOut = await amountOutContract.getAmountOut(
        formatAmountIn,
        amountInput, // Use appropriate reserve for x-OFT/USDC
        amountOutput, // Use appropriate reserve for USDC/x-OFT
      );
      const formatAmountOutMin = ethers.formatUnits(amountOut, destDecimals);
      console.log({ amountOut, formatAmountOutMin });

      // Log transaction payload for debugging
      console.log("tx payload before tx... ", {
        formatAmountIn,
        formatAmountOutMin,
        sourceTokenAddress,
        destTokenAddress,
      });

      const swapContract = new ethers.Contract(
        fiberRouterSmartContractAddress,
        SwapRouterJson,
        signer,
      );

      // const quotationAmount = fetchQuotation(amount, sourceTokenAddress, destTokenAddress)
      console.log(
        formatAmountIn, // USDC amount to swap
        amountOut, // Calculated minimum output (LZ token)
        [sourceTokenAddress, destTokenAddress],
      );
      // Execute swap on Arbitrum
      const tx = await swapContract.omniswap(
        formatAmountIn, // USDC amount to swap
        amountOut, // Calculated minimum output (LZ token)
        [sourceTokenAddress, destTokenAddress], // Path: USDC -> LZ Token
      );

      setSwapTxStatus(1);

      const receipt = await tx.wait();

      // if (receipt) {
      //   setIsSwapping(false)
      //   setisSwappingLong(true)
      // }

      console.log("Swap transaction confirmed:", receipt);
      // Step 1: Listen for the OmniswapInitiated event on Arbitrum
      const omnipoolSwappedAbi = ["event OmniswapSwapped(bytes32 guid)"];
      const omnipoolCompletedAbi = ["event OmniswapCompleted(bytes32 guid)"];
      // Parse the events from the transaction receipt
      const events = receipt.logs
        .map((log: { topics: ReadonlyArray<string>; data: string }) => {
          try {
            return swapContract.interface.parseLog(log);
          } catch (error) {
            // If parsing fails, return null (to handle non-event logs)
            return null;
          }
        })
        .filter((event: null) => event !== null); // Filter out the null values
      console.log(events, "events");
      // Look for the OmniswapInitiated event
      const initiatedEvent = events.find(
        (e: { name: string }) => e.name === "OmniswapInitiated",
      );
      if (initiatedEvent) {
        // Access the 'guid' from args
        const guid = initiatedEvent.args[0]; // This is a bytes32 value (hex string)

        console.log(
          "OmniswapInitiated event detected on Source Network with GUID:",
          guid,
        );
        setSwapTxStatus(2);
        // Step 2: Listen for the 'OmniswapSwapped' event on Base using the same GUID
        const baseOmnipoolContract = new ethers.Contract(
          fiberRouterSmartContractAddress,
          omnipoolSwappedAbi,
          baseProvider,
        );

        baseOmnipoolContract.on("OmniswapSwapped", (eventGuid) => {
          console.log({ eventGuid });
          if (eventGuid === guid) {
            console.log(
              "OmniswapSwapped event detected on Destination Network with GUID:",
              eventGuid,
            );
            setSwapTxStatus(3);
          }
        });
        // Step 3: Listen for the 'OmniswapCompleted' event back on Arbitrum
        const sourceOmnipoolContract = new ethers.Contract(
          fiberRouterSmartContractAddress,
          omnipoolCompletedAbi,
          sourceProvider,
        );

        sourceOmnipoolContract.on("OmniswapCompleted", (completedGuid) => {
          console.log({ completedGuid });

          if (completedGuid === guid) {
            console.log(
              "OmniswapCompleted event detected on Source Network with GUID:",
              completedGuid,
            );
            console.log("Swap process successfully completed!");
            setSwapTxStatus(0);
            setAmount("");
            setIsSwapping(false);
            setInfoMessage({
              message: "Swap Completed",
              keepVisible: false,
            });
          }
        });
      }
    } catch (error) {
      console.error("Error while swapping:", error);
      setIsSwapping(false);
      setSwapTxStatus(0); // Hide long-running loader on error
      if ((error as any)?.shortMessage) {
        setIsSwapping(false);
        setIsApproving(false);
        setErrorMessage((error as any)?.shortMessage);
      } else if (error instanceof Error) {
        setErrorMessage(error.message);
      }
    }
  };

  const handleApproveAction = () => {
    if (
      !IsObjectEmpty(sourceNetwork) &&
      !IsObjectEmpty(sourceToken) &&
      !IsObjectEmpty(destinationNetwork) &&
      !IsObjectEmpty(destinationToken) &&
      amount
    ) {
      approveContractAllocation(
        sourceToken.tokenContractAddress,
        walletAddress,
        sourceNetwork.multiSwapFiberRouterSmartContractAddress,
        new Web3(sourceNetwork.rpcUrl),
        new Big(amount),
        sendWeb3Transaction,
      );
    }
  };

  const handleQuotationAction = async (
    sourceWalletAddress: string,
    sourceNetworkChainId: string,
    sourceTokenAddress: string,
    sourceAmount: string,
    destinationWalletAddress: string,
    destinationNetworkChainId: string,
    destinationTokenAddress: string,
    referralCode: string = "",
    doFetchGasFeeEstimation: boolean = false,
  ) => {
    setFetchingQuotation(true);
    try {
      // const quoteInfoResponse =
      //   await swapServerRequest.getTokenCategorizedAndQuoteInformation(
      //     sourceWalletAddress,
      //     sourceTokenAddress,
      //     sourceNetworkChainId,
      //     sourceAmount,
      //     destinationWalletAddress,
      //     destinationTokenAddress,
      //     destinationNetworkChainId,
      //     searchParams.get("sourceSlippage")!,
      //     searchParams.get("destinationSlippage")!,
      //     referralCode,
      //   );
      // const {
      //   sourceTokenCategorizedInfo,
      //   destinationTokenCategorizedInfo,
      //   isCCTP,
      //   isStargate,
      //   feeDistribution,
      //   platformFee,
      // } = quoteInfoResponse.data.body.data;
      setIsCCTP(isCCTP);
      setIsStargate(isStargate);
      let fakeQuoteInfoResponse;
      // if (sourceNetworkChainId !== destinationNetworkChainId) {
      //   fakeQuoteInfoResponse =
      //     await swapServerRequest.getFakeTokenCategorizedAndQuoteInformation(
      //       sourceWalletAddress,
      //       sourceTokenAddress,
      //       sourceNetworkChainId,
      //       sourceAmount,
      //       destinationWalletAddress,
      //       destinationTokenAddress,
      //       destinationNetworkChainId,
      //       searchParams.get("sourceSlippage")!,
      //       searchParams.get("destinationSlippage")!,
      //       "0.5",
      //     );
      // }
      // const fakeDestinationTokenCategorizedInfo =
      //   fakeQuoteInfoResponse?.data?.body?.data
      //     ?.destinationTokenCategorizedInfo;
      // const updatedTokenQuotationInfo = {
      //   sourceTokenQuotationInfo: {
      //     ...sourceTokenCategorizedInfo,
      //     sourceSlippage: quoteInfoResponse.data.body.data.sourceSlippage,
      //   },
      //   destinationTokenQuotationInfo: {
      //     ...destinationTokenCategorizedInfo,
      //     destinationSlippage:
      //       quoteInfoResponse.data.body.data.destinationSlippage,
      //   },
      //   // fakeDestinationTokenQuotationInfo: {
      //   //   ...fakeDestinationTokenCategorizedInfo,
      //   //   destinationSlippage:
      //   //     fakeQuoteInfoResponse?.data?.body?.data?.destinationSlippage,
      //   // },
      //   feeDistribution: feeDistribution,
      //   platformFee,
      // };
      // setTokenQuotationInfo(updatedTokenQuotationInfo);
      setFetchingQuotation(false);
      // if (doFetchGasFeeEstimation) {
      //   fetchGasFeeEstimationAction(
      //     sourceWalletAddress,
      //     sourceNetworkChainId,
      //     sourceTokenAddress,
      //     updatedTokenQuotationInfo,
      //     destinationWalletAddress,
      //     destinationNetworkChainId,
      //     destinationTokenAddress,
      //     isCCTP,
      //     isStargate,
      //   );
      // }
    } catch (error) {
      console.log({ error });
      setFetchingQuotation(false);
      if (error instanceof AxiosError) {
        setErrorMessage(error?.response?.data?.status?.message);
      } else if (error instanceof Error) {
        setErrorMessage(error?.message);
      }
    }
  };

  const fetchGasFeeEstimationAction = async (
    sourceWalletAddress: string,
    sourceNetworkChainId: string,
    sourceTokenAddress: string,
    tokenQuotationInfo: { [key: string]: any },
    destinationWalletAddress: string,
    destinationNetworkChainId: string,
    destinationTokenAddress: string,
    isCCTP: boolean,
    isStargate: boolean,
  ) => {
    try {
      const {
        sourceTokenQuotationInfo,
        destinationTokenQuotationInfo,
        fakeDestinationTokenQuotationInfo,
        feeDistribution,
      } = tokenQuotationInfo;

      setFetchingGasFeeEstimation(true);
      // const gasFeeResponse = await swapServerRequest.getGasFee(
      //   sourceNetworkChainId,
      //   sourceTokenAddress,
      //   sourceTokenQuotationInfo?.sourceAmount,
      //   sourceTokenQuotationInfo?.sourceAmountIn,
      //   sourceTokenQuotationInfo?.sourceAmountOut,
      //   sourceTokenQuotationInfo?.sourceOneInchData,
      //   sourceTokenQuotationInfo?.type,
      //   sourceTokenQuotationInfo?.sourceOneInchSelector,
      //   sourceWalletAddress,
      //   destinationNetworkChainId,
      //   destinationTokenAddress,
      //   sourceNetworkChainId === destinationNetworkChainId
      //     ? destinationTokenQuotationInfo?.destinationOneInchSelector
      //     : fakeDestinationTokenQuotationInfo?.destinationOneInchSelector,
      //   sourceNetworkChainId === destinationNetworkChainId
      //     ? destinationTokenQuotationInfo?.destinationAmountIn
      //     : fakeDestinationTokenQuotationInfo?.destinationAmountIn,
      //   sourceNetworkChainId === destinationNetworkChainId
      //     ? destinationTokenQuotationInfo?.destinationAmountOut
      //     : fakeDestinationTokenQuotationInfo?.destinationAmountOut,
      //   sourceNetworkChainId === destinationNetworkChainId
      //     ? destinationTokenQuotationInfo?.destinationOneInchData
      //     : fakeDestinationTokenQuotationInfo?.destinationOneInchData,
      //   sourceNetworkChainId === destinationNetworkChainId
      //     ? destinationTokenQuotationInfo?.type
      //     : fakeDestinationTokenQuotationInfo?.type,
      //   destinationWalletAddress
      //     ? destinationWalletAddress
      //     : sourceWalletAddress,
      //   destinationTokenQuotationInfo.destinationAmountIn,
      //   destinationTokenQuotationInfo?.destinationAmountOut,
      //   isCCTP,
      //   isStargate,
      //   feeDistribution,
      // );
      setFetchingGasFeeEstimation(false);
      // setEstimatedGasFee(gasFeeResponse?.data?.body);
    } catch (error) {
      console.log({ error });
      if (error instanceof AxiosError) {
        if (
          error?.response?.data?.status?.message.includes("insufficient funds")
        ) {
          setErrorMessage("Insufficient funds to pay for gas fee");
        } else {
          setErrorMessage(error?.response?.data?.status?.message);
        }
      } else if (error instanceof Error) {
        setErrorMessage(error?.message);
      }
      setFetchingGasFeeEstimation(false);
    }
  };

  // const handleConfirmSwapAction = async (
  //   sourceWalletAddress: string,
  //   sourceNetworkChainId: string,
  //   sourceTokenAddress: string,
  //   tokenQuotationInfo: { [key: string]: any },
  //   destinationWalletAddress: string,
  //   destinationNetworkChainId: string,
  //   destinationTokenAddress: string,
  //   estimatedGasFee: string,
  //   isCCTP: boolean,
  //   isStargate: boolean,
  // ) => {
  //   try {
  //     setIsSwapping(true);
  //     const response = await swapServerRequest.getSwapApi(
  //       sourceWalletAddress,
  //       sourceTokenAddress,
  //       sourceNetworkChainId,
  //       tokenQuotationInfo?.sourceTokenQuotationInfo?.type,
  //       tokenQuotationInfo?.sourceTokenQuotationInfo?.sourceAmount,
  //       tokenQuotationInfo?.sourceTokenQuotationInfo?.sourceAmountIn,
  //       tokenQuotationInfo?.sourceTokenQuotationInfo?.sourceAmountOut,
  //       tokenQuotationInfo?.sourceTokenQuotationInfo?.sourceOneInchData,
  //       tokenQuotationInfo?.sourceTokenQuotationInfo?.sourceOneInchSelector,
  //       destinationWalletAddress,
  //       destinationNetworkChainId,
  //       destinationTokenAddress,
  //       tokenQuotationInfo?.destinationTokenQuotationInfo?.type,
  //       tokenQuotationInfo?.destinationTokenQuotationInfo
  //         ?.destinationOneInchData,
  //       tokenQuotationInfo?.destinationTokenQuotationInfo
  //         ?.destinationOneInchSelector,
  //       tokenQuotationInfo?.destinationTokenQuotationInfo?.destinationAmountIn,
  //       tokenQuotationInfo?.destinationTokenQuotationInfo?.destinationAmountOut,
  //       tokenQuotationInfo?.destinationTokenQuotationInfo?.destinationAmount,
  //       estimatedGasFee,
  //       isCCTP,
  //       isStargate,
  //       tokenQuotationInfo?.feeDistribution,
  //     );

  //     if (response) {
  //       let transaction = response.data.body.data;
  //       console.log({ transaction });
  //       const payload = {
  //         from: transaction.from,
  //         to: transaction.contract,
  //         data: transaction.data,
  //         value:
  //           transaction.value !== "undefined" ? transaction.value : undefined,
  //       };
  //       sendWeb3Transaction(payload);
  //     }
  //   } catch (error) {
  //     console.log(error);
  //     setIsSwapping(false);
  //     if (error instanceof AxiosError) {
  //       setErrorMessage(error?.response?.data?.status?.message);
  //     } else if (error instanceof Error) {
  //       setErrorMessage(error?.message);
  //     }
  //   }
  // };

  // const handleSwapAndWithdrawAction = async (
  //   hash: string,
  //   sourceNetworkId: string,
  //   sourceCabnId: string,
  //   tokenQuotationInfo: { [key: string]: any },
  //   destinationNetworkId: string,
  //   destinationCabnId: string,
  //   estimatedGasFee: { [key: string]: any },
  //   authToken: string,
  //   isCCTP: boolean,
  //   isStargate: boolean,
  // ) => {
  //   try {
  //     reset();
  //     setSwapHash(hash);
  //     let txStatus = "";
  //     if (isCCTP) {
  //       setInfoMessage({
  //         message: getTransactionInfoMessage("cctp"),
  //         keepVisible: true,
  //       });
  //     } else if (isStargate) {
  //       setInfoMessage({
  //         message: getTransactionInfoMessage("stargate"),
  //         keepVisible: true,
  //       });
  //     }
  //     while (txStatus !== "swapWithdrawCompleted") {
  //       const response =
  //         await swapServerRequest.doSwapAndWithdrawTxAfterSwapApi(
  //           hash,
  //           sourceNetworkId,
  //           sourceCabnId,
  //           tokenQuotationInfo?.sourceTokenQuotationInfo?.type,
  //           tokenQuotationInfo?.sourceTokenQuotationInfo?.sourceAmount,
  //           walletAddress,
  //           tokenQuotationInfo?.sourceTokenQuotationInfo?.sourceBridgeAmount,
  //           tokenQuotationInfo?.sourceTokenQuotationInfo?.sourceOneInchData,
  //           tokenQuotationInfo?.sourceTokenQuotationInfo?.sourceOneInchSelector,
  //           destinationNetworkId,
  //           destinationCabnId,
  //           tokenQuotationInfo?.destinationTokenQuotationInfo?.type,
  //           tokenQuotationInfo?.destinationTokenQuotationInfo
  //             ?.destinationOneInchData,
  //           tokenQuotationInfo?.destinationTokenQuotationInfo
  //             ?.destinationAmountIn,
  //           tokenQuotationInfo?.destinationTokenQuotationInfo
  //             ?.destinationAmountOut,
  //           tokenQuotationInfo?.destinationTokenQuotationInfo
  //             ?.destinationOneInchSelector,
  //           estimatedGasFee,
  //           searchParams.get("sourceSlippage") ||
  //           tokenQuotationInfo?.sourceTokenQuotationInfo?.sourceSlippage,
  //           searchParams.get("destinationSlippage") ||
  //           tokenQuotationInfo?.destinationTokenQuotationInfo
  //             ?.destinationSlippage,
  //           authToken,
  //           isCCTP,
  //           isStargate,
  //         );
  //       const transactionReceipt =
  //         response?.data?.body?.swapAndWithdrawTransaction;

  //       setSwapAndWithdrawTxStatus(transactionReceipt?.status);
  //       const withdrawTxHash =
  //         transactionReceipt?.withdrawTransactions?.shift()?.transactionId;
  //       setWithdrawTxHash(withdrawTxHash);
  //       if (
  //         transactionReceipt?.status === "swapWithdrawCompleted" &&
  //         withdrawTxHash
  //       ) {
  //         setSwapHash("");
  //         setWithdrawTxHash("");
  //         setIsSwapping(false);
  //         setTokenQuotationInfo({});
  //         setEstimatedGasFee({});
  //         setDestinationAddress("");
  //         setSwapAndWithdrawTxStatus("");
  //         setInfoMessage({
  //           message: "",
  //           keepVisible: false,
  //         });
  //         navigate(`/swap-success/${hash}`);
  //         break;
  //       }
  //       if (
  //         transactionReceipt?.status === "swapFailed" ||
  //         transactionReceipt?.status === "swapWithdrawFailed" ||
  //         transactionReceipt?.status === "generatorSignatureFailed" ||
  //         transactionReceipt?.status === "validatorSignatureFailed" ||
  //         transactionReceipt?.status === "masterValidationFailed"
  //       ) {
  //         setIsSwapping(false);
  //         setSwapHash("");
  //         setEstimatedGasFee({});
  //         setErrorMessage("Swap Failed");
  //         break;
  //       }
  //       await new Promise((res) => setTimeout(res, 2000));
  //     }
  //   } catch (error) {
  //     console.log(error);
  //     if (error instanceof AxiosError) {
  //       setErrorMessage(error?.response?.data?.status?.message);
  //     } else if (error instanceof Error) {
  //       setErrorMessage(error?.message);
  //     }
  //   }
  // };

  const getButtonVariant = () => {
    if (
      !isConnected ||
      (sourceNetwork?.chainId &&
        destinationNetwork?.chainId &&
        currentNetworkChainId !== Number(sourceNetwork?.chainId) &&
        !fetchingQuotation)
    )
      return "primary";

    if (
      isSwapping ||
      swapTxStatus > 0 ||
      fetchingQuotation ||
      fetchingGasFeeEstimation ||
      (swapAndWithdrawTxStatus &&
        !FailedStatuses.includes(swapAndWithdrawTxStatus)) ||
      !amount ||
      IsObjectEmpty(sourceNetwork) ||
      IsObjectEmpty(sourceToken) ||
      IsObjectEmpty(destinationNetwork) ||
      IsObjectEmpty(destinationToken)
    ) {
      return "tertiary";
    }

    return "primary";
  };

  const getButtonTitle = () => {
    if (errorMessage && errorMessage.includes("Insufficient funds")) {
      return errorMessage;
    }
    if (swapTxStatus === 1) {
      return "Request Initiated ...";
    }
    if (swapTxStatus === 2) {
      return "Bridging and Swapping ...";
    }
    if (swapTxStatus === 3) {
      return "Transferring to Wallet...";
    }
    if (fetchingQuotation) return "Fetching Latest Quote";
    if (fetchingGasFeeEstimation) return "Fetching Gas Fee";
    if (!isConnected) return "Connect Wallet";
    if (FailedStatuses.includes(swapAndWithdrawTxStatus)) {
      return "Swap Failed! Click for more info";
    }
    if (
      swapAndWithdrawTxStatus &&
      !FailedStatuses.includes(swapAndWithdrawTxStatus)
    )
      return SwapAndWithdrawTxStatuses[swapAndWithdrawTxStatus];
    if (
      sourceNetwork?.chainId &&
      destinationNetwork?.chainId &&
      currentNetworkChainId !== Number(sourceNetwork?.chainId)
    )
      return "Switch Network";
    if (
      IsObjectEmpty(sourceNetwork) ||
      IsObjectEmpty(sourceToken) ||
      IsObjectEmpty(destinationNetwork) ||
      IsObjectEmpty(destinationToken)
    )
      return "Select Network and Token";
    if (!amount) return "Enter an Amount";
    if (isSwapping || getTotalEstimatedGasFee()) return "CONFIRM SWAP";
    if (
      Number(allowance) < Number(amount) ||
      estimatedGasFee?.destination?.gasPriceInMachine
    ) {
      return "SWAP";
    }

    return "SWAP";
  };

  const isButtonDisabled = () => {
    return (
      ((!amount && currentNetworkChainId === Number(sourceNetwork?.chainId)) ||
        fetchingQuotation ||
        swapTxStatus > 0 ||
        fetchingGasFeeEstimation ||
        (isSwapping && !(swapHash || withdrawTxHash)) ||
        isApproving ||
        !sourceNetwork?.chainId ||
        !destinationNetwork?.chainId) &&
      isConnected
    );
  };

  const getButtonPostfix = () => {
    if (
      (isSwapping && (withdrawTxHash || swapHash)) ||
      FailedStatuses.includes(swapAndWithdrawTxStatus) ||
      (errorMessage && errorMessage.includes("Insufficient funds"))
    ) {
      return <ReactIcons.GoLinkExternal />;
    }
  };

  const getButtonPrefix = () => {
    if (
      isSwapping ||
      swapTxStatus > 0 ||
      isApproving ||
      (fetchingQuotation && isConnected) ||
      (fetchingGasFeeEstimation && isConnected) ||
      (swapAndWithdrawTxStatus &&
        !FailedStatuses.includes(swapAndWithdrawTxStatus))
    ) {
      return <img src={Gif.Loader} className="h-4 w-4" />;
    }
  };
  console.log(allowance, "allowance");
  const handleButtonClick = () => {
    if (
      isConnected &&
      sourceNetwork?.chainId &&
      destinationNetwork?.chainId &&
      sourceToken?.currency?.symbol &&
      destinationToken?.currency?.symbol &&
      sourceNetwork?.chainId === destinationNetwork?.chainId &&
      sourceToken?.currency?.symbol === destinationToken?.currency?.symbol
    ) {
      setErrorMessage("Source and destination cannot be the same.");
      return;
    }
    if (errorMessage && errorMessage.includes("Insufficient funds")) {
      window.open(getSwapInsufficientFundsHelpLink(), "_blank");
      setErrorMessage("");
    }
    if (FailedStatuses.includes(swapAndWithdrawTxStatus)) {
      window.open(getSwapFailedHelpLink(), "_blank");
      setSwapAndWithdrawTxStatus("");
      return;
    }
    if (isSwapping && (swapHash || withdrawTxHash)) {
      const link = withdrawTxHash
        ? `${destinationNetwork?.blockExplorerUrl}/tx/${withdrawTxHash}`
        : swapHash
          ? `${sourceNetwork?.blockExplorerUrl}/tx/${swapHash}`
          : "";
      window.open(link, "_blank");
      return;
    } else if (!isConnected) {
      setShowDialog(true);
    } else if (
      amount?.includes(".") &&
      amount?.split(".")?.length &&
      Number(amount?.split(".")[1].length) > sourceToken?.decimals
    ) {
      setErrorMessage(
        `Amount should have ${sourceToken?.decimals} decimal places`,
      );
      return;
    } else if (
      sourceNetwork?.chainId &&
      destinationNetwork?.chainId &&
      currentNetworkChainId !== Number(sourceNetwork?.chainId)
    ) {
      switchWeb3Network(sourceNetwork?.chainId);
    } else if (
      !(Number(amount) > 0 && Number(amount) <= Number(tokenBalance))
    ) {
      setErrorMessage(
        `You don't have that much ${sourceToken?.currency?.symbol} tokens to swap`,
      );
    } else if (
      amount &&
      !sourceToken?.isNative &&
      Number(allowance) < Number(amount)
    ) {
      // console.log({
      //   amount,
      //   allowance,
      //   amountInFloat: new Big(amount!).valueOf(),
      //   allowanceInFloat: new Big(allowance!).valueOf(),
      //   amountInNumber: Number(amount),
      //   allowanceInNumber: Number(allowance),
      //   condInBig: new Big(allowance!).lt(new Big(amount!)),
      //   cond: Number(allowance) < Number(amount),
      // });
      setErrorMessage("");
      handleApproveAction();
    } else {
      setErrorMessage("");
      sendSwapTx(
        walletAddress,
        sourceNetwork?.multiSwapFiberRouterSmartContractAddress,
        amount,
        sourceToken?.tokenContractAddress,
        destinationToken?.tokenContractAddress,
      );
      // handleConfirmSwapAction(
      //   walletAddress,
      //   sourceNetwork?.chainId,
      //   sourceToken?.tokenContractAddress,
      //   tokenQuotationInfo,
      //   destinationAddress,
      //   destinationNetwork?.chainId,
      //   destinationToken?.tokenContractAddress,
      //   estimatedGasFee?.destination?.gasPriceInMachine
      //     ? estimatedGasFee?.destination?.gasPriceInMachine
      //     : "",
      //   isCCTP,
      //   isStargate,
      // );
      // } else if (
      //   !IsObjectEmpty(tokenQuotationInfo) &&
      //   tokenQuotationInfo?.sourceTokenQuotationInfo?.sourceAmount
      // ) {
      //   setErrorMessage("");
      // handleQuotationAction(
      //   walletAddress,
      //   sourceNetwork?.chainId,
      //   sourceToken?.tokenContractAddress,
      //   tokenQuotationInfo?.sourceTokenQuotationInfo?.sourceAmount,
      //   destinationAddress,
      //   destinationNetwork?.chainId,
      //   destinationToken?.tokenContractAddress,
      //   searchParams.get("referralCode")!,
      //   true,
      // );
    }
  };

  if (isApproving) {
    return <ApproveCard />;
  }

  return (
    <>
      <div className="mb-10 flex flex-col">
        <Card variant="primary" className="shadow-2xl">
          <section className="flex flex-col">
            <header className="flex h-8 items-center justify-between">
              <div className="text-base text-white">
                {!IsObjectEmpty(estimatedGasFee) ? (
                  <div className="flex items-center">
                    <ReactIcons.IoArrowBack
                      size={20}
                      className="cursor-pointer"
                      onClick={() => setEstimatedGasFee({})}
                    />
                    <span className="ml-2 text-sm sm:text-base">
                      Swap Preview
                    </span>
                  </div>
                ) : (
                  <span>MultiSwap</span>
                )}
              </div>
              <div className="flex items-center">
                <div className="mr-1 sm:mr-4">
                  <Button
                    variant="secondary"
                    postfix={
                      <div className="flex items-center justify-around">
                        {/* <img
                          src={Icons.SwapIcon}
                          alt="ms icon"
                          width={15}
                          className="mr-2"
                        /> */}
                        <span className="text-nowrap text-white">
                          Coming Soon
                        </span>
                        <ReactIcons.RiArrowDropDownLine
                          size={25}
                          color="white"
                        />
                      </div>
                    }
                    title="Fee Token:"
                    rounded="md"
                    className="h-8 text-nowrap p-2 text-xs"
                    style={{ color: "#6F767E" }}
                  />
                </div>
                <div
                  className="cursor-pointer"
                  onClick={() => setShowSlippage(true)}
                >
                  <img src={Icons.SettingIcon} alt="setting icon" />
                </div>
              </div>
            </header>
            <article className="relative">
              <SwapDetailsSelectorCard title="YOU PAY" side="source" />

              <div
                className="mx-auto w-9 cursor-pointer rounded-xl border-2 border-background bg-[#15181A] p-[8px] sm:absolute sm:left-[15.5rem] sm:top-[7.3rem]"
                onClick={() => switchSelections()}
              >
                <ReactIcons.FaArrowDown color="white" />
              </div>

              <SwapDetailsSelectorCard
                title="YOU GET"
                side="destination"
                quationAmount={quotationAmount}
              />

              {getTotalEstimatedGasFee() ? (
                <SwapFeeDetailCard
                  platformFee={tokenQuotationInfo?.platformFee}
                  sourceNetworkCurrency={sourceNetwork?.networkCurrencySymbol}
                  estimatedGasFee={getTotalEstimatedGasFee()}
                  estimatedSwapTime={
                    IsObjectEmpty(estimatedSwapTime) ? "80" : estimatedSwapTime
                  }
                  slippage={
                    Number(searchParams.get("sourceSlippage")) +
                    Number(searchParams.get("destinationSlippage"))
                  }
                />
              ) : tokenQuotationInfo?.platformFee ? (
                <SwapPlatformFeeCard
                  platformFee={tokenQuotationInfo?.platformFee}
                />
              ) : null}

              <section
                className={`flex ${showAddressField ? "h-16 flex-col " : "justify-between"} rounded-md bg-[#11131580] px-2 sm:h-8 sm:flex-row sm:items-center sm:justify-between sm:bg-inherit`}
              >
                <div className="flex items-center text-white">
                  <a id="send-to">
                    <ReactIcons.IoInformationCircleOutline />
                  </a>
                  <Tooltip
                    anchorSelect="#send-to"
                    className="!w-auto !to-backgroundDark !text-[10px] !text-secondary"
                  >
                    Swaps are received on same wallet. To send funds to
                    different wallet enter it below
                  </Tooltip>
                  <span className="ml-2 py-1 text-[10px]">
                    Send to a different wallet
                  </span>
                </div>

                {showAddressField ? (
                  <div className="flex w-full justify-between rounded-md bg-backgroundDark p-1 text-xs sm:w-[60%]">
                    <InputField
                      value={destinationAddress}
                      type="text"
                      placeholder="Enter Destination Wallet Address"
                      className="w-full px-2 text-white"
                      inputFieldClassName="w-full"
                      onChange={(e) => handleDestinationAddressAction(e)}
                    />
                    <Button
                      variant="tertiary"
                      title="PASTE"
                      className="py-1 text-[10px]"
                      onClick={() => handlePasteAction()}
                    />
                  </div>
                ) : (
                  <button
                    className="block whitespace-nowrap rounded-lg bg-inherit text-[10px] text-primary sm:bg-backgroundDark sm:p-2 sm:text-xs sm:text-secondary"
                    onClick={() => setShowAddressField(true)}
                  >
                    Add Address
                  </button>
                )}
              </section>
              <Button
                variant={`${getButtonVariant()}`}
                title={`${getButtonTitle()}`}
                className="mt-4 w-full text-xl"
                prefix={getButtonPrefix()}
                postfix={getButtonPostfix()}
                disabled={isButtonDisabled()}
                onClick={handleButtonClick}
              />
            </article>
          </section>
        </Card>
        <img src={Images.FerrumBanner} alt="ferrum banner" className="" />
      </div>
      <ConnectWalletDialog
        show={showDialog}
        onHide={() => setShowDialog(false)}
      />
      <SlippageDialog
        show={showSlippage}
        onHide={() => setShowSlippage(false)}
      />
    </>
  );
};

export default SwapCard;
