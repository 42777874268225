import React, { useEffect } from "react";
import SwapCard from "./SwapCard";
import ProvidersListCard from "./ProvidersListCard";
import { useSearchParams } from "react-router-dom";
import * as TokenServerRequest from "../../api/TokenCrud";
import * as SwapServerRequest from "../../api/SwapCrud";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import {
  allNetworksListState,
  allTokensListState,
  amountState,
  destinationAddressState,
  destinationNetworkState,
  destinationTokenState,
  ErrorMessagesState,
  fetchingQuotationState,
  sourceNetworkState,
  sourceTokenState,
  swapAndWithdrawTxStatusState,
  tokenQuotationInfoState,
  // URLState,
} from "../../recoil/swap";
import { useWalletConnector } from "foundry";
import { AxiosError } from "axios";
import { authTokenState, userState } from "../../recoil/auth";
import { TOKENS } from "../../utils/constants";

const SwapPage = () => {
  // URL data
  // const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const fromChainId = searchParams.get("fromChainId");
  const toChainId = searchParams.get("toChainId");
  const fromToken = searchParams.get("fromToken");
  const toToken = searchParams.get("toToken");
  const sourceAmount = searchParams.get("amount");
  const to = searchParams.get("to");

  // states
  // const URLData = useRecoilValue(URLState);
  const authToken = useRecoilValue(authTokenState);
  const user = useRecoilValue(userState);
  const setErrorMessage = useSetRecoilState(ErrorMessagesState);
  const setAmount = useSetRecoilState(amountState);
  const setSwapAndWithdrawTxStatus = useSetRecoilState(
    swapAndWithdrawTxStatusState,
  );
  const [destinationAddress, setDestinationAddress] = useRecoilState(
    destinationAddressState,
  );
  const [sourceNetwork, setSourceNetwork] = useRecoilState(sourceNetworkState);
  const [destinationNetwork, setDestinationNetwork] = useRecoilState(
    destinationNetworkState,
  );
  const [sourceToken, setSourceToken] = useRecoilState(sourceTokenState);
  const [destinationToken, setDestinationToken] = useRecoilState(
    destinationTokenState,
  );
  const [fetchingQuotation, setFetchingQuotation] = useRecoilState(
    fetchingQuotationState,
  );
  const [tokenQuotationInfo, setTokenQuotationInfo] = useRecoilState(
    tokenQuotationInfoState,
  );
  const allNetworksList = useRecoilValue(allNetworksListState);
  const [tokens, setTokens] = useRecoilState<any>(allTokensListState);
  const { walletAddress } = useWalletConnector();

  useEffect(() => {
    fetchTokens({}, authToken);
  }, [authToken]);

  useEffect(() => {
    if (
      fromChainId &&
      allNetworksList.length &&
      sourceNetwork?.chainId !== fromChainId
    ) {
      const network = getNetworkByChainId(allNetworksList, fromChainId);
      setSourceNetwork(network);
    }
    if (
      toChainId &&
      allNetworksList.length &&
      destinationNetwork?.chainId !== toChainId
    ) {
      const network = getNetworkByChainId(allNetworksList, toChainId);
      setDestinationNetwork(network);
    }
    if (
      fromChainId &&
      fromToken &&
      sourceToken?.tokenContractAddress !== fromToken &&
      tokens.length
    ) {
      getTokenByAddress(tokens, fromToken, fromChainId, "source");
    }
    if (
      toChainId &&
      toToken &&
      destinationToken?.tokenContractAddress !== toToken &&
      tokens.length
    ) {
      getTokenByAddress(tokens, toToken, toChainId, "destination");
    }
    if (sourceAmount) {
      setAmount(sourceAmount);
    }
    if (to) {
      setDestinationAddress(to);
    }
  }, [
    fromChainId,
    toChainId,
    fromToken,
    toToken,
    sourceAmount,
    to,
    allNetworksList,
    tokens,
  ]);

  useEffect(() => {
    const controller = new AbortController(); // Create a new AbortController
    const { signal } = controller;
    if (
      walletAddress &&
      sourceAmount &&
      sourceAmount !== undefined &&
      sourceAmount !== null &&
      Number(sourceAmount) > 0 &&
      sourceNetwork?.chainId &&
      destinationNetwork?.chainId &&
      sourceToken?.tokenContractAddress &&
      destinationToken?.tokenContractAddress
    ) {
      if (
        sourceAmount?.includes(".") &&
        sourceAmount?.split(".")?.length &&
        Number(sourceAmount?.split(".")[1].length) > sourceToken?.decimals
      ) {
        setErrorMessage(
          `Amount should have ${sourceToken?.decimals} decimal places`,
        );
        return;
      }
      if (
        sourceNetwork?.chainId === destinationNetwork?.chainId &&
        sourceToken?.currency?.symbol === destinationToken?.currency?.symbol
      ) {
        setErrorMessage("Source and destination cannot be the same.");
      } else {
        setErrorMessage("");
        setSwapAndWithdrawTxStatus("");
        // setFetchingQuotation(true);

        // handleAmountChangeEvent(
        //   sourceAmount,
        //   sourceNetwork?.chainId,
        //   destinationNetwork?.chainId,
        //   sourceToken?.tokenContractAddress,
        //   destinationToken?.tokenContractAddress,
        //   walletAddress,
        //   destinationAddress,
        //   sourceToken?.sourceSlippage,
        //   destinationToken?.destinationSlippage,
        //   signal, // Pass the signal to the function // A callback to mark this request as outdated
        //   searchParams.get("referralCode")!,
        // );
      }
    }
    return () => {
      controller.abort();
    };
  }, [
    walletAddress,
    sourceAmount,
    sourceNetwork?.chainId,
    destinationNetwork?.chainId,
    sourceToken?.tokenContractAddress,
    destinationToken?.tokenContractAddress,
  ]);

  // useEffect(() => {
  //   navigate({
  //     search: `?${createSearchParams(URLData)}`,
  //   });
  // }, [URLData]);

  const fetchTokens = async (params: any, authToken?: string) => {
    try {
      // const response = await TokenServerRequest.getCABNsList(params, authToken);
      setTokens([
        // ...response?.data?.body?.currencyAddressesByNetworks,
        ...TOKENS,
      ]);
    } catch (error) {
      console.error("Error fetching networks:", error);
      if (error instanceof AxiosError) {
        setErrorMessage(error?.response?.data?.status?.message);
      } else if (error instanceof Error) {
        setErrorMessage(error?.message);
      }
    }
  };

  const getNetworkByChainId = (
    allNetworksList: any[],
    chainId: number | string,
  ) => {
    let network;
    allNetworksList.forEach((item: any) => {
      if (item?.chainId === chainId) {
        network = item;
      }
    });
    return network;
  };

  const getTokenByAddress = async (
    allToken: any[],
    address: string,
    chainId: string,
    direction: string,
  ) => {
    let token;
    allToken.forEach((item: any) => {
      if (item?.tokenContractAddress === address) {
        token = item;
      }
    });
    if (!token) {
      const cabnListParameters = {
        chainId: chainId,
        tokenContractAddress: address,
      };
      // const response =
      // await TokenServerRequest.getCABNsList(cabnListParameters);
      token = TOKENS[0];
    }
    if (direction === "source") {
      setSourceToken(token);
    } else if (direction === "destination") {
      setDestinationToken(token);
    }
  };

  const handleAmountChangeEvent = async (
    amount: string,
    sourceChainId: string,
    destinationChainId: string,
    sourceTokenAddress: string,
    destinationTokenAddress: string,
    sourceWalletAddress: string,
    destinationWalletAddress: string,
    sourceSlippage: string = "2",
    destinationSlippage: string = "2",
    signal: any,
    referralCode?: string,
  ) => {
    try {
      setTokenQuotationInfo({});
      setFetchingQuotation(true);
      const response =
        await SwapServerRequest.getTokenCategorizedAndQuoteInformation(
          sourceWalletAddress,
          sourceTokenAddress,
          sourceChainId,
          amount,
          destinationWalletAddress,
          destinationTokenAddress,
          destinationChainId,
          sourceSlippage,
          destinationSlippage,
          referralCode,
          signal,
        );
      if (signal.aborted) {
        return; // If the request was aborted, do not proceed
      }
      setTokenQuotationInfo({
        sourceTokenQuotationInfo: {
          ...response.data.body.data.sourceTokenCategorizedInfo,
          sourceSlippage: response.data.body.data.sourceSlippage,
        },
        destinationTokenQuotationInfo: {
          ...response.data.body.data.destinationTokenCategorizedInfo,
          destinationSlippage: response.data.body.data.destinationSlippage,
        },
        feeDistribution: response.data.body.data.feeDistribution,
        platformFee: response.data.body.data.platformFee,
      });
      setFetchingQuotation(false);
    } catch (error) {
      if (signal.aborted) {
        return; // If the request was aborted, do not proceed
      }
      if (error instanceof AxiosError) {
        setErrorMessage(error?.response?.data?.status?.message);
      } else if (error instanceof Error) {
        setErrorMessage(error?.message);
      }
      setFetchingQuotation(false);
      console.error("Error fetching quote information:", error);
    }
  };

  return (
    <>
      <SwapCard />
    </>
  );
};

export default SwapPage;
