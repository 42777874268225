export const DEFAULT_TOKEN_LOGO =
  "https://gateway-revamp.s3.us-east-2.amazonaws.com/multiswap/Token-import.png";

export const SwapAndWithdrawTxStatuses: { [key: string]: string } = {
  generatorSignatureCreated: "Generator Signature Created",
  generatorSignatureFailed: "Swap failed!",
  validatorSignatureCreated: "Validator Signature Created",
  validatorSignatureFailed: "Swap failed!",
  masterValidationFailed: "Swap failed!",
  swapPending: "Tx Processing - Please Wait",
  swapCreated: "Swap Created",
  swapCompleted: "Tx Processing - Please Wait",
  swapFailed: "Swap failed! Please try again.",
  swapWithdrawGenerated: "Swap Withdraw Generated",
  swapWithdrawPending: "Tx Processing - Please Wait",
  swapWithdrawFailed: "Swap Withdraw Failed",
  swapWithdrawCompleted: "Swap Withdraw Completed",
};

export const SwapAndWithdrawErrorMessages: { [key: string]: string } = {
  generatorSignatureFailed: "Generator Signature Failed",
  validatorSignatureFailed: "Validator Signature Failed",
  masterValidationFailed: "Master Validation Failed",
  swapFailed: "Swap Failed",
  swapWithdrawFailed: "Swap Withdraw Failed",
};

export const FailedStatuses = [
  "generatorSignatureFailed",
  "validatorSignatureFailed",
  "masterValidationFailed",
  "swapFailed",
  "swapWithdrawFailed",
];

const TX_INFO_MESSAGE: { [key: string]: string } = {
  stargate:
    "The transaction is processed through Stargate, and it may take up to 1 minute to process.",
  cctp: "This transaction is processed through CCTP and can take up to 30 mins to complete.",
};

export const getTransactionInfoMessage = (provider: string) => {
  return TX_INFO_MESSAGE[provider];
};

export const getTwitterPostContent = (
  sourceTokenSymbol: string,
  sourceNetworkName: string,
  destinationTokenSymbol: string,
  destinationNetworkName: string,
) => {
  return `https://twitter.com/intent/post?text=%F0%9F%9A%80%20Just%20used%20%400xMultiSwap%20by%20%40FerrumNetwork%0A%20to%20swap%20%24${sourceTokenSymbol}%20on%20%23${sourceNetworkName}%20for%20%24${destinationTokenSymbol}%20on%20%23${destinationNetworkName}%20with%20a%20click%20on%20a%20button.%0A%0ABlazing%20fast%20swaps%20and%20super%20easy%20to%20use%21%20%F0%9F%94%A5%0A%0A%F0%9F%91%89Try%20it%20yourself%3A%0Ahttps%3A%2F%2Fswap.multiswap.network%0A%0A%24FRM%20%24SWAPS`;
};

export const getSwapFailedHelpLink = () =>
  "https://help.ferrumnetwork.io/en/articles/9053975-swap-failed";

export const getSwapInsufficientFundsHelpLink = () =>
  "https://help.ferrumnetwork.io/en/articles/9128013-intrinsic-gas-too-low-this-usually-means-you-do-not-have-enough-gas";

export const getDocsURL = () =>
  "https://docs.ferrumnetwork.io/ferrum-network-ecosystem/v/multiswap-and-multichain-liquidity-pool-bridge/";

export const getExplorerURL = () => process.env.REACT_APP_ANALYTICS_URL;

export const getSupportLink = () => "https://help.ferrumnetwork.io/";

export const ProviderAssetsLogo: { [key: string]: string } = {
  Foundary: "https://etherscan.io/token/images/ferrumnetwork_32.png?=v454",
  "1Inch": "https://etherscan.io/token/images/1inch_32.png?=v454",
};

export const getDiscordSupportLink = () =>
  "https://discord.com/invite/HEfKq57asd";

export const TABLE_HEADER = [
  "FROM",
  "TO",
  "DATE UTC",
  "FROM HASH",
  "TO HASH",
  "PROVIDER",
  "STATUS",
  "DETAILS",
];

export const TX_TABLE_HEADER = [
  "FROM",
  "TO",
  "DATE UTC",
  "FROM HASH",
  "TO HASH",
  "COMPLETED TIME",
  "PROVIDER",
  "STATUS",
];

export const FRM_CONTRACT_ADDRESS: { [key: string]: string } = {
  "56": "0xa719b8ab7ea7af0ddb4358719a34631bb79d15dc",
  "42161": "0x9f6abbf0ba6b5bfa27f4deb6597cc6ec20573fda",
};

export const CONTRACT_ADDRESSES = {
  FERRUM: {
    FiberRouter: "0x2cfeeaeb816d3e595cbd34d1fa1aad2ff9fc6ffe",
    Pool: "0xd5f0a5a53954ccd74bcf0492c79d04dfe26b73ed",
  },
  ARBITRUM: {
    FiberRouter: "0xf998Ddc9FD55a357a5967Cc779901a4fB8a39E2a",
    Pool: "0xbd4961aed7d06834dc7111f554dc0fd09be54bb0",
  },
  BASE: {
    FiberRouter: "0x38470fd4e613B05Dad0B60E74B670Fea41BaB9C7",
    Pool: "0x5b0208c22cb590cd094fed20712881aa58363b40",
  },

  BSC: {
    FiberRouter: "0xCBe27EFa8F7e83A901C073bb4aAb402E674aD279",
    Pool: "0x2325c5a863c532021f5ae0465e30f2896b447745",
  },
};

// export const TOKEN_ADDRESSES = {
//   BASE: {
//     USDC: "0x833589fCD6eDb6E08f4c7C32D4f71b54bdA02913",
//     AxelarToken: "0xD2b1dB268A9c03009452aD16988045DD36c8FE13",
//   },
//   ARBITRUM: {
//     USDC: "0xaf88d065e77c8cC2239327C5EDb3A432268e5831",
//     AxelarToken: "0xD2b1dB268A9c03009452aD16988045DD36c8FE13",
//   },
//   BSC: {
//     USDC: "0x8AC76a51cc950d9822D68b83fE1Ad97B32Cd580d",
//     AxelarToken: "0xD2b1dB268A9c03009452aD16988045DD36c8FE13",
//   },
//   FERRUM: {
//     USDC: "0x833589fCD6eDb6E08f4c7C32D4f71b54bdA02913",
//     AxelarToken: "0xD2b1dB268A9c03009452aD16988045DD36c8FE13",
//   },
//   FANTOM: {
//     USDC: "0x04068DA6C83AFCFA0e13ba15A6696662335D5B75",
//     AxelarToken: "0xD2b1dB268A9c03009452aD16988045DD36c8FE13",
//   },
//   AVALANCHE: {
//     USDC: "0xB97EF9Ef8734C71904D8002F8b6Bc66Dd9c48a6E",
//     AxelarToken: "0xD2b1dB268A9c03009452aD16988045DD36c8FE13",
//   },
// };

export const NETWORKS = [
  {
    name: "Arbitrum",
    nameInLower: "arbitrum",
    networkShortName: "arb",
    ferrumNetworkIdentifier: "42161",
    chainId: "42161",
    networkId: "42161",
    rpcUrl:
      "https://nd-499-825-018.p2pify.com/5d8bab30e1462f48144c36f18d2ee958",
    blockExplorerUrl: "https://arbiscan.io/",
    networkCurrencySymbol: "ETH",
    dexInputCurrencySymbolList: ["ETH"],
    isTestnet: false,
    isActive: true,
    isAllowedOnGateway: true,
    isAllowedOnMultiSwap: true,
    logo: "https://gateway-revamp.s3.us-east-2.amazonaws.com/multiswap/arbitrum-logo.png",
    publicRpcUrl: "publicRpcUrl",
    backupRpcUrl: "backupRpcUrl",
    positionForMultiSwap: 0,
    multiSwapFiberRouterSmartContractAddress:
      "0xd8b7a9401A23e91DAf2c53fB04DC48ea782A6875",
    isNonEVM: false,
    isAllowedDynamicGasValues: true,
    threshold: 5,
    createdAt: "2023-02-23T14:24:33.200Z",
    updatedAt: "2023-02-08T16:42:41.786Z",
    parentId: null,
    networkCurrencyAddressByNetwork: null,
    __v: 0,
    multiswapNetworkFIBERInformation: "64243254655a734d5b51dc9b",
  },

  {
    name: "Base",
    nameInLower: "base",
    networkShortName: "base",
    ferrumNetworkIdentifier: "8453",
    chainId: "8453",
    networkId: "8453",
    rpcUrl:
      "https://base-mainnet.core.chainstack.com/e7aa01c976c532ebf8e2480a27f18278",
    blockExplorerUrl: "https://basescan.org/",
    networkCurrencySymbol: "ETH",
    dexInputCurrencySymbolList: [""],
    isTestnet: false,
    isActive: true,
    isAllowedOnGateway: false,
    isAllowedOnMultiSwap: true,
    logo: "https://avatars.githubusercontent.com/u/108554348?s=200&v=4",
    publicRpcUrl: "",
    backupRpcUrl: "",
    positionForMultiSwap: 0,
    multiSwapFiberRouterSmartContractAddress:
      "0xd8b7a9401A23e91DAf2c53fB04DC48ea782A6875",
    isNonEVM: false,
    isAllowedDynamicGasValues: true,
    threshold: 5,
    createdAt: "2024-05-02T17:01:38.447Z",
    updatedAt: "2024-05-02T20:39:46.439Z",
    parentId: null,
    __v: 0,
    networkCurrencyAddressByNetwork: "622f6c497a0cc65e0dee643c",
    contractAddress: "",
    multiswapNetworkFIBERInformation: "6634cbd50c3f167e5d2a4340",
  },

  {
    name: "Optimism",
    nameInLower: "optimism",
    networkShortName: "OP",
    ferrumNetworkIdentifier: "10",
    chainId: "10",
    networkId: "10",
    rpcUrl:
      "https://optimism-mainnet.core.chainstack.com/7cb5109bd1c125224315d9b753cc0e45",
    blockExplorerUrl: "https://optimistic.etherscan.io/",
    networkCurrencySymbol: "ETH",
    dexInputCurrencySymbolList: [""],
    isTestnet: false,
    isActive: true,
    isAllowedOnGateway: false,
    isAllowedOnMultiSwap: true,
    logo: "https://gateway-revamp.s3.us-east-2.amazonaws.com/multiswap/op-logo.png",
    publicRpcUrl: "",
    backupRpcUrl: "",
    positionForMultiSwap: 0,
    multiSwapFiberRouterSmartContractAddress:
      "0xd8b7a9401A23e91DAf2c53fB04DC48ea782A6875",
    isNonEVM: false,
    isAllowedDynamicGasValues: true,
    threshold: 10,
    createdAt: "2024-03-23T17:01:38.447Z",
    updatedAt: "2024-03-23T20:39:46.439Z",
    _id: "66013b633955fc170b613dd6",
    parentId: null,
    user: "61a6553288dd8363bb954c7a",
    __v: 0,
    networkCurrencyAddressByNetwork: null,
    contractAddress: "",
    multiswapNetworkFIBERInformation: "66013b963955fc170b613dda",
  },
  {
    name: "Polygon",
    nameInLower: "polygon",
    networkShortName: "Polygon",
    ferrumNetworkIdentifier: "137",
    chainId: "137",
    networkId: "137",
    rpcUrl:
      "https://nd-003-843-665.p2pify.com/7af52d3a77b5d19f11de64357253ca16",
    blockExplorerUrl: "https://polygonscan.com/",
    dexInputCurrencySymbolList: ["MATIC"],
    isTestnet: false,
    isActive: true,
    __v: 0,
    isAllowedOnGateway: false,
    networkCurrencySymbol: "MATIC",
    mainnetCurrencySymbol: "",
    networkCurrencyAddressByNetwork: {
      $oid: "61f27433bd8a6417f5fd1b95",
    },
    isAllowedOnMultiSwap: false,
    logo: "https://gateway-revamp.s3.us-east-2.amazonaws.com/multiswap/ic_polygon.png",
    multiSwapFiberRouterSmartContractAddress:
      "0xd8b7a9401A23e91DAf2c53fB04DC48ea782A6875",
    isNonEVM: false,
    multiswapNetworkFIBERInformation: {
      $oid: "63c18d150bef4b637c462d53",
    },
    isAllowedDynamicGasValues: false,
    threshold: 5,
  },
];

export const TOKENS = [
  // Arbitrum - USDC
  {
    tokenContractAddress: "0xaf88d065e77c8cC2239327C5EDb3A432268e5831",
    isAllowedOnMultiSwap: true,
    isActive: true,
    decimals: 6,
    network: {
      _id: "1",
      name: "ARBITRUM",
      nameInLower: "arbitrum",
      networkShortName: "arb",
      chainId: "42161",
      logo: "https://gateway-revamp.s3.us-east-2.amazonaws.com/multiswap/arbitrum-logo.png",
      multiSwapFiberRouterSmartContractAddress:
        "0xd8b7a9401A23e91DAf2c53fB04DC48ea782A6875",
      isNonEVM: false,
    },
    currency: {
      name: "USD Coin",
      nameInLower: "usd coin",
      symbol: "USDC",
      logo: "https://arbiscan.io/token/images/centre-usdc_28.png",
    },
    isDefault: true,
  },
  // Optimism - USDC
  {
    tokenContractAddress: "0x0b2C639c533813f4Aa9D7837CAf62653d097Ff85",
    isAllowedOnMultiSwap: true,
    isActive: true,
    decimals: 6,
    network: {
      _id: "1",
      name: "Optimism",
      nameInLower: "optimism",
      networkShortName: "OP",
      chainId: "10",
      logo: "https://gateway-revamp.s3.us-east-2.amazonaws.com/multiswap/arbitrum-logo.png",
      multiSwapFiberRouterSmartContractAddress:
        "0xd8b7a9401A23e91DAf2c53fB04DC48ea782A6875",
      isNonEVM: false,
    },
    currency: {
      name: "USD Coin",
      nameInLower: "usd coin",
      symbol: "USDC",
      logo: "https://arbiscan.io/token/images/centre-usdc_28.png",
    },
    isDefault: true,
  },
  // Polygon - USDC
  {
    tokenContractAddress: "0x3c499c542cEF5E3811e1192ce70d8cC03d5c3359",
    isAllowedOnMultiSwap: true,
    isActive: true,
    decimals: 6,
    network: {
      _id: "1",
      name: "Polygon",
      nameInLower: "polygon",
      networkShortName: "Polygon",
      chainId: "137",
      logo: "https://gateway-revamp.s3.us-east-2.amazonaws.com/multiswap/arbitrum-logo.png",
      multiSwapFiberRouterSmartContractAddress:
        "0xd8b7a9401A23e91DAf2c53fB04DC48ea782A6875",
      isNonEVM: false,
    },
    currency: {
      name: "USD Coin",
      nameInLower: "usd coin",
      symbol: "USDC",
      logo: "https://arbiscan.io/token/images/centre-usdc_28.png",
    },
    isDefault: true,
  },
  // Base - USDC
  {
    tokenContractAddress: "0x833589fCD6eDb6E08f4c7C32D4f71b54bdA02913",
    isAllowedOnMultiSwap: true,
    isActive: true,
    decimals: 6,
    network: {
      _id: "2",
      name: "BASE",
      nameInLower: "base",
      networkShortName: "base",
      chainId: "8453",
      logo: "https://avatars.githubusercontent.com/u/108554348?s=200&v=4",
      multiSwapFiberRouterSmartContractAddress:
        "0xd8b7a9401A23e91DAf2c53fB04DC48ea782A6875",
      isNonEVM: false,
    },
    currency: {
      name: "USD Coin",
      nameInLower: "usd coin",
      symbol: "USDC",
      logo: "https://arbiscan.io/token/images/centre-usdc_28.png",
    },
    isDefault: true,
  },

  // Arbitrum - x-ITS
  {
    tokenContractAddress: "0xcE15516130F5293F9ae507C3b1cCCD4cA2c92640",
    isAllowedOnMultiSwap: true,
    isActive: true,
    decimals: 6,
    network: {
      _id: "6",
      name: "ARBITRUM",
      nameInLower: "arbitrum",
      networkShortName: "arb",
      chainId: "42161",
      logo: "https://gateway-revamp.s3.us-east-2.amazonaws.com/multiswap/arbitrum-logo.png",
      multiSwapFiberRouterSmartContractAddress:
        "0xd8b7a9401A23e91DAf2c53fB04DC48ea782A6875",
      isNonEVM: false,
    },
    currency: {
      name: "x-ITS",
      nameInLower: "x-ITS",
      symbol: "x-ITS",
      logo: "https://s2.coinmarketcap.com/static/img/coins/64x64/17799.png",
    },
    isDefault: true,
  },

  // Base - x-ITS
  {
    tokenContractAddress: "0xcE15516130F5293F9ae507C3b1cCCD4cA2c92640",
    isAllowedOnMultiSwap: true,
    isActive: true,
    decimals: 6,
    network: {
      _id: "7",
      name: "BASE",
      nameInLower: "base",
      networkShortName: "base",
      chainId: "8453",
      logo: "https://avatars.githubusercontent.com/u/108554348?s=200&v=4",
      multiSwapFiberRouterSmartContractAddress:
        "0xd8b7a9401A23e91DAf2c53fB04DC48ea782A6875",
      isNonEVM: false,
    },
    currency: {
      name: "x-ITS",
      nameInLower: "x-ITS",
      symbol: "x-ITS",
      logo: "https://s2.coinmarketcap.com/static/img/coins/64x64/17799.png",
    },
    isDefault: true,
  },
  // Polygon - x-ITS
  {
    tokenContractAddress: "0xcE15516130F5293F9ae507C3b1cCCD4cA2c92640",
    isAllowedOnMultiSwap: true,
    isActive: true,
    decimals: 6,
    network: {
      _id: "7",
      name: "Polygon",
      nameInLower: "Polygon",
      networkShortName: "Polygon",
      chainId: "137",
      logo: "https://avatars.githubusercontent.com/u/108554348?s=200&v=4",
      multiSwapFiberRouterSmartContractAddress:
        "0xd8b7a9401A23e91DAf2c53fB04DC48ea782A6875",
      isNonEVM: false,
    },
    currency: {
      name: "x-ITS",
      nameInLower: "x-ITS",
      symbol: "x-ITS",
      logo: "https://s2.coinmarketcap.com/static/img/coins/64x64/17799.png",
    },
    isDefault: true,
  },
  // Optimism - x-ITS
  {
    tokenContractAddress: "0xcE15516130F5293F9ae507C3b1cCCD4cA2c92640",
    isAllowedOnMultiSwap: true,
    isActive: true,
    decimals: 6,
    network: {
      _id: "7",
      name: "Optimism",
      nameInLower: "optimism",
      networkShortName: "Op",
      chainId: "10",
      logo: "https://avatars.githubusercontent.com/u/108554348?s=200&v=4",
      multiSwapFiberRouterSmartContractAddress:
        "0xd8b7a9401A23e91DAf2c53fB04DC48ea782A6875",
      isNonEVM: false,
    },
    currency: {
      name: "x-ITS",
      nameInLower: "x-ITS",
      symbol: "x-ITS",
      logo: "https://s2.coinmarketcap.com/static/img/coins/64x64/17799.png",
    },
    isDefault: true,
  },

  // Arbitrum - LayerZero
  {
    tokenContractAddress: "0x3ca437941Db4b4797046bc7B814fD9C80293bC6a",
    isAllowedOnMultiSwap: true,
    isActive: true,
    decimals: 6,
    network: {
      _id: "1",
      name: "ARBITRUM",
      nameInLower: "arbitrum",
      networkShortName: "arb",
      chainId: "42161",
      logo: "https://s2.coinmarketcap.com/static/img/coins/64x64/26997.png",
      multiSwapFiberRouterSmartContractAddress:
        "0xd8b7a9401A23e91DAf2c53fB04DC48ea782A6875",
      isNonEVM: false,
    },
    currency: {
      name: "X-OFT",
      nameInLower: "x-oft",
      symbol: "x-OFT",
      logo: "https://s2.coinmarketcap.com/static/img/coins/64x64/26997.png",
    },
    isDefault: true,
  },
  // Base - LayerZero
  {
    tokenContractAddress: "0x3ca437941Db4b4797046bc7B814fD9C80293bC6a",
    isAllowedOnMultiSwap: true,
    isActive: true,
    decimals: 6,
    network: {
      _id: "7",
      name: "BASE",
      nameInLower: "base",
      networkShortName: "base",
      chainId: "8453",
      logo: "https://s2.coinmarketcap.com/static/img/coins/64x64/26997.png",
      multiSwapFiberRouterSmartContractAddress:
        "0xd8b7a9401A23e91DAf2c53fB04DC48ea782A6875",
      isNonEVM: false,
    },
    currency: {
      name: "X-OFT",
      nameInLower: "x-oft",
      symbol: "x-OFT",
      logo: "https://s2.coinmarketcap.com/static/img/coins/64x64/26997.png",
    },
    isDefault: true,
  },
  {
    tokenContractAddress: "0x3ca437941Db4b4797046bc7B814fD9C80293bC6a",
    isAllowedOnMultiSwap: true,
    isActive: true,
    decimals: 6,
    network: {
      _id: "7",
      name: "Polygon",
      nameInLower: "polygon",
      networkShortName: "polygon",
      chainId: "137",
      logo: "https://s2.coinmarketcap.com/static/img/coins/64x64/26997.png",
      multiSwapFiberRouterSmartContractAddress:
        "0xd8b7a9401A23e91DAf2c53fB04DC48ea782A6875",
      isNonEVM: false,
    },
    currency: {
      name: "X-OFT",
      nameInLower: "x-oft",
      symbol: "x-OFT",
      logo: "https://s2.coinmarketcap.com/static/img/coins/64x64/26997.png",
    },
    isDefault: true,
  },
  {
    tokenContractAddress: "0x3ca437941Db4b4797046bc7B814fD9C80293bC6a",
    isAllowedOnMultiSwap: true,
    isActive: true,
    decimals: 6,
    network: {
      _id: "7",
      name: "Optimism",
      nameInLower: "optimism",
      networkShortName: "Op",
      chainId: "10",
      logo: "https://s2.coinmarketcap.com/static/img/coins/64x64/26997.png",
      multiSwapFiberRouterSmartContractAddress:
        "0xd8b7a9401A23e91DAf2c53fB04DC48ea782A6875",
      isNonEVM: false,
    },
    currency: {
      name: "X-OFT",
      nameInLower: "x-oft",
      symbol: "x-OFT",
      logo: "https://s2.coinmarketcap.com/static/img/coins/64x64/26997.png",
    },
    isDefault: true,
  },
];

export const LIQUIDITY_POOLS: { [key: string]: string } = {
  "x-OFT": "0xD637B96BAeE926998032d30E699292BE1f9D371A",
  "x-ITS": "0x5FA53f26c02E3217C0c3AAF4a9fa0EaeC7c9e350",
};
