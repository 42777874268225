import React, { useEffect, useState } from "react";
import Web3 from "web3";
import { Web3Helper } from "../utils/Web3Helper";

const useTokenBalance = (
  walletAddress: string,
  tokenAddress: string,
  isNativeCurrency: boolean,
  rpc?: string,
) => {
  const [tokenBalance, setTokenBalance] = useState<any>("");
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<any>(null);

  const fetchTokenBalance = async (
    walletAddress: string,
    tokenAddress: string,
    isNativeCurrency: boolean,
    rpc?: string,
  ) => {
    setLoading(true);
    try {
      const web3 = new Web3(rpc || (window as any).ethereum);
      const web3Helper = new Web3Helper(web3);
      if (isNativeCurrency) {

        const balance = await web3Helper.fetchNativeTokenBalance(walletAddress);
        setTokenBalance(balance);
      } else if (tokenAddress) {

        const tokenBalance = await web3Helper.fetchTokenBalance(
          tokenAddress,
          walletAddress,
        );
        setTokenBalance(tokenBalance);
      }
    } catch (error) {
      setError(error);
      console.error(
        isNativeCurrency,
        tokenAddress,
        walletAddress,
        "Error fetching token balance",
        error,
      );
      //   toast.error("Error fetching token balance");
    }
    setLoading(false);
  };

  useEffect(() => {
    if (
      ((tokenAddress && Web3.utils.isAddress(tokenAddress)) ||
        isNativeCurrency) &&
      walletAddress
    ) {
      fetchTokenBalance(walletAddress, tokenAddress, isNativeCurrency, rpc);
    }
  }, [tokenAddress, walletAddress, rpc]);
  return { loading, tokenBalance, error, fetchTokenBalance };
};
export default useTokenBalance;
